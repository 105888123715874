import React, { useContext, useState } from "react";
import axios from "axios";
import AuthContext from "context/auth";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Modal, Row, Spinner, UncontrolledAlert } from "reactstrap";

const CardMascotaPropietario = ({modalFormGuardar,setModalFormGuardar, formMascota,setFormMascota,peticionGet}) => {

    const { token, url_main } = useContext(AuthContext);
    const [spinner, setSpinner] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const url = url_main + "api/mascotas-update/"
    const [file, guarfarFile] = useState(null);
    const [perdido, setPerdido] = useState(false);

    
    const peticionPut = async () => {

        let formData = new FormData();
        
        formData.append('color', formMascota.color);
        formData.append('edad', formMascota.edad);
        formData.append('fecha_vacuna', formMascota.fecha_vacuna);
        formData.append('nombre', formMascota.nombre);
        formData.append('id', formMascota.id);
        formData.append('raza', formMascota.raza);
        formData.append('sexo', formMascota.sexo);
        formData.append('url', formMascota.url);
        formData.append('propietario', formMascota.propietario);

        formData.append('hobbies', formMascota.hobbies);
        formData.append('alimentacion', formMascota.alimentacion?formMascota.alimentacion:"-");
        formData.append('medicamentos', formMascota.medicamentos?formMascota.medicamentos:"-");
        formData.append('perdido', formMascota.perdido);
        formData.append('aviso_busqueda', formMascota.aviso_busqueda?formMascota.aviso_busqueda:"-");

        if (file){
            formData.append('foto', file);
        }
        
        console.log(" Actualizar");
        await axios.put(url+formMascota.id+'/', formData, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            
            setFormMascota(response.data);
            setAlertSuccess(true);
            peticionGet();
            window.setTimeout(() => {
                setAlertSuccess(false);
                setSpinner(false);
                setModalFormGuardar(false);
            }, 2000)
            
        }).catch(error => {
            console.log(error.message);
            setSpinner(false);
            setAlertError(true);
            window.setTimeout(() => {
                setAlertError(false);
            }, 2000)
        });
    }


    const submitForm = e => {
        setSpinner(true);
        e.preventDefault();
        peticionPut();
    }


    const handleChange = async e => {
        e.persist();
        
        if (e.target.name === "perdido"){
            setPerdido(e.target.checked);
            await setFormMascota({
                ...formMascota,
                [e.target.name]: e.target.checked,
            });
        }else{
            await setFormMascota({
                ...formMascota,
                [e.target.name]: e.target.value,
            });
        }
        

    }

    const handleChangeFile = e => {
        e.persist();
        guarfarFile(e.target.files[0])
    }




    return ( <>

        <Modal
            className="modal-dialog-centered modal-lg"
            isOpen={spinner}>
            <div className="modal-body text-center">
                <Spinner className="spinners" type="grow" color="danger" style={{ width: '5rem', height: '5rem' }} />
                <p> Procesando información... </p>
            </div>
        </Modal>

        <div className="modal-header">
                <h3 className="modal-title" id="modal-title-notification">
                    Editar datos de la mascota
                </h3>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalFormGuardar(!modalFormGuardar)} >
                    <span aria-hidden={true}>×</span>
                </button>
        </div>
        <div className="modal-body p-0">

        <UncontrolledAlert className="alert-success position-fixed " fade={true} isOpen={alertSuccess} onClick={() => { setAlertSuccess(false) }}>
                    <span className="alert-inner--icon">
                        <i className="ni ni-bell-55" />
                    </span>{" "}
                    <span className="alert-inner--text">
                        <strong>Mensaje!</strong> Mascota actualizada correctamente.
                    </span>
        </UncontrolledAlert>



        <UncontrolledAlert className="alert-danger position-fixed " fade={true} isOpen={alertError} onClick={() => { setAlertError(false) }}>
                    <span className="alert-inner--icon">
                        <i className="ni ni-bell-55" />
                    </span>{" "}
                    <span className="alert-inner--text">
                        <strong>Cuidado!</strong> Ingrese datos válidos.
                    </span>
        </UncontrolledAlert>


            <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                <Form role="form"
                    onSubmit={submitForm}
                    method="post"
                    encType="multipart/form-data">

                    <div className="pl-lg-0">
                        <Row>

                        <Col md="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="nombre">
                                        Foto
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="foto"
                                        name="foto"
                                        placeholder=""
                                        onChange={handleChangeFile}
                                        type="file">
                                    </Input>
                                </FormGroup>
                            </Col>


                            <Col md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="nombre">
                                        Nombre <span className="text-danger" > * </span>
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="nombre"
                                        name="nombre"
                                        placeholder=""
                                        required="required"
                                        value={formMascota["nombre"]}
                                        onChange={handleChange}
                                        type="text">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="nombre">
                                        Sexo <span className="text-danger" > * </span>
                                    </label>

                                    <div className="custom-control custom-radio ">
                                        <input
                                            className="custom-control-input"
                                            id="macho"
                                            name="sexo"
                                            type="radio"
                                            value="1"
                                            checked={formMascota["sexo"] === '1'}
                                            required="required"
                                            onChange={handleChange}
                                        />
                                        <label className="custom-control-label" htmlFor="macho">
                                            Macho
                                        </label>
                                    </div>

                                    <div className="custom-control custom-radio ">
                                        <input
                                            className="custom-control-input"
                                            id="hembra"
                                            name="sexo"
                                            type="radio"
                                            value="2"
                                            checked={formMascota["sexo"] === '2'}
                                            onChange={handleChange}
                                        />
                                        <label className="custom-control-label" htmlFor="hembra">
                                            Hembra
                                        </label>
                                    </div>

                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="edad">
                                        Edad (Meses) <span className="text-danger" > * </span>
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="edad"
                                        name="edad"
                                        placeholder=""
                                        required="required"
                                        value={formMascota["edad"]}
                                        onChange={handleChange}
                                        type="number">
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col md="2">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="color">
                                        Color
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="color"
                                        name="color"
                                        placeholder=""
                                        value={formMascota["color"]}
                                        onChange={handleChange}
                                        type="color">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="raza">
                                        Raza
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="raza"
                                        name="raza"
                                        placeholder=""
                                        value={formMascota["raza"]}
                                        onChange={handleChange}
                                        type="text">
                                    </Input>
                                </FormGroup>
                            </Col>


                            <Col md="8">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="hobbies">
                                        Hobbies
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="hobbies"
                                        name="hobbies"
                                        placeholder=""
                                        value={formMascota["hobbies"]}
                                        onChange={handleChange}
                                        type="textarea">
                                    </Input>
                                </FormGroup>
                            </Col>




                            <Col md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="alimentacion">
                                        Alimentación
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="alimentacion"
                                        name="alimentacion"
                                        placeholder=""
                                        value={formMascota["alimentacion"]}
                                        onChange={handleChange}
                                        type="textarea">
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="medicamentos">
                                        Medicamento
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="medicamentos"
                                        name="medicamentos"
                                        placeholder=""
                                        value={formMascota["medicamentos"]}
                                        onChange={handleChange}
                                        type="textarea">
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col md="2">
                            <div className="custom-control custom-checkbox ">
                                    <input
                                        className="custom-control-input"
                                        id="perdido"
                                        name="perdido"
                                        value={formMascota["perdido"]}
                                        onChange={handleChange}
                                        type="checkbox">
                                    </input>
                                    <label className="custom-control-label text-danger" htmlFor="perdido">
                                    Reportar como perdido
                                    </label>
                                </div>
                            </Col>

                            {perdido ? 
                            <Col md="8">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="aviso_busqueda">
                                        Aviso de búsqueda
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="aviso_busqueda"
                                        name="aviso_busqueda"
                                        placeholder=""
                                        value={formMascota["aviso_busqueda"]}
                                        onChange={handleChange}
                                        type="textarea">
                                    </Input>
                                </FormGroup>
                            </Col>  : null}

                        

                            </Row>
                            </div>
                        <div className="text-center">
                            <Button
                                className="my-4"
                                color="success btn-block"
                                type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>


 
            </div>



            <div className="modal-footer">

                <Button
                  className=" ml-auto "
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => setModalFormGuardar(!modalFormGuardar)}
                >
                  Cerrar
                </Button>
              </div>
            
    </> );
}
 
export default CardMascotaPropietario;