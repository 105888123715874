import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import AuthContext from "context/auth";
import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/es";

const CardDosis = ({
  formMascota,
  dataVacuna,
  getListaDosis,
  setModalFormGuardar,
  formDosis,
  setFormDosis,
  setAlertSuccess,
  tipoModal,
  dosisLista
}) => {

  const data_user = useMemo(
    () => JSON.parse(localStorage.getItem("@diris_ls-data")),
    []
  );

  const { token, url_main, setToggleAlert, setToggleSpinner } =
    useContext(AuthContext);
  let url_dosis = url_main + "api/dosis/";
  let url_entidad = url_main + "api/entidades/";
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [dataEstablecimiento, setDataEstablecimiento] = useState([]);

  const peticionGetEstablecimiento = async () => {
    await axios
      .get(url_entidad + "?ris=" + data_user.ris, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        setDataEstablecimiento(response.data.results);
      })
      .catch((error) => {
        setToggleAlert({
          isOpen: true,
          message: " Error,"+error.message,
          type: "danger",
        });
      });
  };

  const peticionPost = async () => {
    delete formDosis.id;
    await axios
      .post(url_dosis, formDosis, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        setToggleSpinner(false);
        getListaDosis();
        setToggleAlert({
          isOpen: true,
          message: " Dosis actualizada correctamente",
          type: "success",
        });
        setModalFormGuardar(false);
      })
      .catch((error) => {
        setToggleSpinner(false);
        setToggleAlert({
          isOpen: true,
          message: "Ups, ocurrio un error: "+error.message,
          type: "danger",
        });
        setModalFormGuardar(false);
      });
  };

  const peticionPut = async () => {
    console.log(" Actualizar - jeje  ")
    console.log(formDosis)
    await axios
      .put(formDosis.url, formDosis, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        setFormDosis(response.data);
        getListaDosis();
        setToggleAlert({
          isOpen: true,
          message: " Dosis actualizada correctamente",
          type: "success",
        });
        setToggleSpinner(false);
        setAlertSuccess(true);
        setModalFormGuardar(false);
      })
      .catch((error) => {
        setToggleSpinner(false);
        setToggleAlert({
          isOpen: true,
          message: "Ups, ocurrio un error: "+error.message,
          type: "danger",
        });
        setModalFormGuardar(false);
      });
  };

  const handleChange = async (e) => {
    e.persist();
    await setFormDosis({
      ...formDosis,
      [e.target.name]: e.target.value,
    });
  };

  const sumDay = () => {
    var date = new Date(formDosis.fecha);
    date.setDate(date.getDate() + 1);
    return date;
  };

  const handleDayChange = (selectedDayInto, modifiers, dayPickerInput) => {
    // const input = dayPickerInput.getInput();
    if (selectedDayInto) {
      setSelectedDay(selectedDayInto);
      setFormDosis({
        ...formDosis,
        fecha: selectedDayInto.toISOString().substring(0, 10),
      });
    } else {
      setSelectedDay(undefined);
    }
  };

  const cerrarModal = async () => {
    setModalFormGuardar(false);
  };

  const submitForm = (e) => {
    console.log(" SUMBIMT");
    setToggleSpinner(true);
    e.preventDefault();

    if (dosisLista.filter(v => v.dosis == formDosis.dosis && v.id !== formDosis.id).length > 0 || formMascota.dosis == formDosis.dosis){
      setToggleSpinner(false);
      setToggleAlert({
        isOpen: true,
        message: "Cuidado!! Ya existe el número de dosis",
        type: "danger",
      });
    }else{

      if (selectedDay == "Invalid Date") {
        setToggleSpinner(false);
        setToggleAlert({
          isOpen: true,
          message: " Ingrese una fecha de vacunación correcta",
          type: "danger",
        });
      } else {
        if (tipoModal === "insertar") {
          setModalFormGuardar(false);
          peticionPost();
        } else {
          peticionPut();
        }
      }
    }
 


  };

  useEffect(() => {
    setSelectedDay(sumDay());
    peticionGetEstablecimiento();
  }, []);

  return (
    <>
      <div className="modal-header">
        <h3 className="modal-title" id="modal-title-notification" >
          Guardar Dosis
        </h3>
      </div>
      <div className="modal-body p-0">
        <Card>
          {/* <CardHeader style={{ paddingBottom: 2 }}>
            Datos de la dosis
          </CardHeader> */}

          <CardBody>
            <Form role="form" onSubmit={(e) => submitForm(e)} method="post">
              <div className="pl-lg-0">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="vacuna">
                        Vacuna <span className="text-danger"> * </span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="vacuna"
                        // disabled={data_user.ris !== formMascota["ris_id"] && formMascota["ris_id"]}
                        name="vacuna"
                        required="required"
                        value={formDosis["vacuna"]}
                        onChange={handleChange}
                        type="select"
                      >
                        <option value=""> -- -- </option>
                        {dataVacuna.map((object) => (
                          <option key={object.id} value={object.url}>
                            {object.nombre} (Lote: {object.lote}){" "}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="edad">
                        Edad (Meses) <span className="text-danger"> * </span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="edad"
                        // disabled={data_user.ris !== formMascota["ris_id"]&& formMascota["ris_id"]}
                        name="edad"
                        placeholder=""
                        required="required"
                        value={formDosis["edad"]}
                        onChange={handleChange}
                        type="number"
                      ></Input>
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="dosis">
                        Número de vacuna <span className="text-danger"> * </span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="dosis"
                        // disabled={data_user.ris !== formMascota["ris_id"]&& formMascota["ris_id"]}
                        name="dosis"
                        placeholder=""
                        required="required"
                        value={formDosis["dosis"]}
                        onChange={handleChange}
                        type="number"
                      ></Input>
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="fecha_vacuna"
                      >
                        Fecha Vacunación{" "}
                        <span className="text-danger"> * </span>
                      </label>

                      <DayPickerInput
                        required={true}
                        readOnly={true}
                        value={selectedDay}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        placeholder={`${formatDate(new Date(), "LL", "es")}`}
                        dayPickerProps={{
                          locale: "es",
                          localeUtils: MomentLocaleUtils,
                          initialMonth: new Date(2023, 9),
                          disabledDays: {
                            before: new Date(2022, 7, 1),
                            after: new Date(),
                          },
                        }}
                        onDayChange={handleDayChange}
                        inputProps={{
                          readOnly: true,
                          required: true,
                          className: "form-control-alternative",
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="establecimiento"
                      >
                        Establecimiento.{" "}
                        <span className="text-danger"> * </span>
                      </label>

                      {data_user.ris !== formMascota["ris_id"] &&
                      formMascota["ris_id"] ? (
                        <Input
                          className="form-control-alternative"
                          id="establecimiento"
                          name="establecimiento"
                          placeholder=""
                          disabled="true"
                          required="required"
                          value=" -- "
                          onChange={handleChange}
                          type="input"
                        />
                      ) : (
                        <Input
                          className="form-control-alternative"
                          id="establecimiento"
                          name="establecimiento"
                          placeholder=""
                          required="required"
                          value={formDosis["establecimiento"]}
                          onChange={handleChange}
                          type="select"
                        >
                          <option value=""> -- -- </option>
                          {dataEstablecimiento.map((object) => (
                            <option key={object.id} value={object.url}>
                              {object.nombre}
                            </option>
                          ))}
                        </Input>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="warning"
                  onClick={() => cerrarModal()}
                >
                  Cancelar
                </Button>
                <Button className="my-4" color="primary" type="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
export default CardDosis;
