
var ris = [

  {
    id: 1,
    name:"RIS BCO-CHO-SCO"
  },
  {
    id: 2,
    name:"RIS LURIN Y BALNERARIOS"
  },
  {
    id: 3,
    name:"RIS PACHACAMAC"
  },
  {
    id: 4,
    name:"RIS SJM"
  },
  {
    id: 5,
    name:"RIS VES"
  },
  {
    id: 6,
    name:"RIS VMT"
  },
  {
    id: 7,
    name:"DIRIS SEDE ADMINISTRATIVA"
  },
];

export default ris;
