/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Modal, Spinner, UncontrolledAlert } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import AuthProvider from "context/auth/Provider";
import { useMemo } from "react";
import AuthContext from "context/auth";

const Admin = (props) => {
  const data_user = useMemo(
    () => JSON.parse(localStorage.getItem("@diris_ls-data")),
    []
  );

  const { toggleAlert, setToggleAlert } = useContext(AuthContext);

  const { toggleSpinner } = useContext(AuthContext);

  const mainContent = React.useRef(null);
  const location = useLocation();
  const [showBienvenida, setShowBienvenida] = useState(false);

  // React.useEffect(() => {
  //   setShowBienvenida(true);
  //   window.setTimeout(() => {
  //     setShowBienvenida(false);
  //   }, 3000);
  // }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    var ACCESOS = [""];

    if (data_user.rol == "DIGITADOR") {
      ACCESOS = ["Propietarios", "Mascotas"];
    }

    if (data_user.rol == "REPORTES") {
      ACCESOS = [
        "Reporte por fechas",
        "Reporte consolidado",
        "Mascota ris",
        "Digitadores",
        "N° Vacuna Digitadores",
      ];
    }

    if (data_user.rol == "ADMINISTRADOR_RIS") {
      ACCESOS = ["Reporte por fechas"];
    }

    if (data_user.rol == "PROPIETARIO") {
      ACCESOS = ["Personalizar Mascota"];
    }

    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (ACCESOS.includes(prop.name)) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
          return null;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const closeAlert = async () => {
    window.setTimeout(() => {
      setToggleAlert({
        isOpen: false,
        message: "",
        type: "",
      });
    }, 4000);
  };

  useEffect(() => {
    if (toggleAlert.isOpen) {
      closeAlert();
    }
  }, [toggleAlert]);

  return (
    <>
      {/* <AuthProvider> */}
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/logo-bicentenario.png").default,
          imgAlt: "...",
        }}
      />
      {/* </AuthProvider> */}
      <div className="main-content" ref={mainContent}>
        {/* <AuthProvider> */}
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        {/* </AuthProvider> */}

        <Modal className="modal-dialog-centered modal-lg" isOpen={toggleSpinner}>
          <div className="modal-body text-center">
            <Spinner
              className="spinners"
              type="grow"
              color="danger"
              style={{ width: "5rem", height: "5rem" }}
            />
            <p>Procesando información...</p>
          </div>
        </Modal>

        <UncontrolledAlert
          style={{ zIndex: "99999", marginTop:"1%", boxShadow:"2px 2px 20px #000" }}
          className={"position-fixed alert-" + toggleAlert?.type}
          fade={true}
          isOpen={toggleAlert?.isOpen}
          onClick={() => {
            setToggleAlert({
              isOpen: false,
              message: "",
              type: "",
            });
          }}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-bell-55" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Mensaje!</strong> {toggleAlert?.message}
          </span>
        </UncontrolledAlert>

        <UncontrolledAlert
          className="alert-info position-fixed "
          fade={true}
          isOpen={showBienvenida}
          onClick={() => {
            setShowBienvenida(false);
          }}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-bell-55" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Bienvenido: </strong> {data_user?.nombre_completo}
          </span>
        </UncontrolledAlert>

        {/* <AuthProvider> */}
        <Switch>
          {getRoutes(routes)}
          {data_user.rol == "ADMINISTRADOR_RIS" ? (
            <Redirect from="*" to="/admin/personas" />
          ) : null}

          {data_user.rol == "PROPIETARIO" ? (
            <Redirect from="*" to="/admin/propietario-mascotas" />
          ) : null}

          {data_user.rol == "REPORTES" ? (
            <Redirect from="*" to="/admin/reporte-establecimiento" />
          ) : null}
        </Switch>
        {/* </AuthProvider> */}

        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
