import React, { useState, useContext } from "react";
import axios from "axios";
import AuthContext from "../context/auth";
import { useHistory } from "react-router";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Modal,
    Spinner,
    UncontrolledAlert,
} from "reactstrap";

const Login = () => {

    const {onLogin, url_main } = useContext(AuthContext);

    const [form, setForm] = useState({
        username: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const [formValid, setFormValid] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const history = useHistory();

    let url = url_main+ "login-api/";
    let url_user = url_main+ "api/usuarios/1?login=true";

    const handleValidation = () => {
        delete axios.defaults.headers.common["Authorization"];

        var valid = false;

        setFormValid(false);

        if (form["username"] === '') {
            valid = true;
            setFormValid(true);
            setErrors({
                ...errors,
                'username': "is-invalid"
            });
        }

        if (form["password"] === '') {
            valid = true;
            setFormValid(true);
            setErrors({
                ...errors,
                'password': "is-invalid"
            });
        }


        return valid;
    }


    const peticionGetUser = async (token) => {
        await axios.get(url_user, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            onLogin(token,response.data);
            history.push("/admin/propietarios");
            setSpinner(false);
        }).catch(error => {
            console.log(" Error !! ",error.message);
        });
    }


    const peticionPost = async () => {
        delete form.id;
        await axios.post(url, form, {}).then(response => {
            peticionGetUser(response.data.token);
        }).catch(error => {
            setSpinner(false);
            setFormValid(true);
        });
    }


    const handleChange = async e => {
        e.persist();
        await setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }


    const submitForm = e => {
        e.preventDefault();
        if (handleValidation()) {
            console.log(" VALID ");
        } else {
            setSpinner(true);
            peticionPost();
        }
    }


    return (
        <>

            <UncontrolledAlert className="alert-danger position-fixed " fade={true} isOpen={formValid} onClick={() => { setFormValid(false) }}>
                <span className="alert-inner--icon">
                    <i className="ni ni-bell-55" />
                </span>{" "}
                <span className="alert-inner--text">
                    <strong>Cuidado!</strong> Usuario invalido
                </span>
            </UncontrolledAlert>


            <Modal
                className="modal-dialog-centered modal-lg"
                isOpen={spinner}>
                <div className="modal-body text-center">
                    <Spinner className="spinners" type="grow" color="danger" style={{ width: '5rem', height: '5rem' }} />
                    <p>
                        Procesando información...
                    </p>
                </div>
            </Modal>


            <Col lg="5" md="7" className="mt-lg-8">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-0">
                        <div className="text-muted text-center mt-0 mb-3">
                            <small>Ingrese sus credenciales s </small>
                        </div>

                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">

                        <Form
                            role="form"
                            onSubmit={submitForm}
                            method="post"
                            encType="multipart/form-data">

                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-single-02" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Usuario"
                                        type="text"
                                        name="username"
                                        className={errors["username"]}
                                        value={form["username"]}
                                        onChange={handleChange}
                                    // invalid={true}
                                    // invalid={true}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Contraseña"
                                        type="password"
                                        name="password"
                                        className={errors["password"]}
                                        value={form["password"]}
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id=" customCheckLogin"
                                    type="checkbox"
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor=" customCheckLogin"
                                >
                                </label>
                            </div>
                            <div className="text-center">
                                <Button className="my-4" color="primary" type="submit">
                                    Ingresar ss
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                <Row className="mt-3">
                    <Col xs="6">
                        <a
                            className="text-light btn btn-dark"
                            onClick={()=>history.push("/main/index")}>
                                
                            <i className="ni ni-air-baloon"></i>  <small>  Ir Inicio.</small>
                        </a>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default Login;
