import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import AuthContext from "context/auth";
import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import CardEliminarMascota from "./CardEliminarMascota";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/es";
import ListaDosis from "views/dosis/listaDosis.jsx";

const CardMascota = ({
  formMascota,
  setFormMascota,
  peticionGetMascota,
  url,
  cardFormGuardar,
  setCardFormGuardar,
  tipoModal,
  setTipoModal,
  setColorBotton
}) => {
  const data_user = useMemo(
    () => JSON.parse(localStorage.getItem("@diris_ls-data")),
    []
  );

  const { token, url_main, setToggleAlert, setToggleSpinner } =
    useContext(AuthContext);
  let url_vacuna = url_main + "api/vacunas/";
  let url_entidad = url_main + "api/entidades/";
  const [dataVacuna, setDataVacuna] = useState([]);
  const [dataEstablecimiento, setDataEstablecimiento] = useState([]);
  const [modalFormEliminar, setModalFormEliminar] = useState(false);
  const [selectedDay, setSelectedDay] = useState(undefined);

  const peticionGetEstablecimiento = async () => {
    await axios
      .get(url_entidad + "?ris=" + data_user.ris, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        setDataEstablecimiento(response.data.results);
      })
      .catch((error) => {
        setToggleAlert({
          isOpen: true,
          message: " Error," + error.message,
          type: "danger",
        });
      });
  };

  const peticionGetVacuna = async () => {
    await axios
      .get(url_vacuna, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        setDataVacuna(response.data.results);
      })
      .catch((error) => {
        setToggleAlert({
          isOpen: true,
          message: " Error," + error.message,
          type: "danger",
        });
      });
  };

  const peticionPost = async () => {
    delete formMascota.id;
    await axios
      .post(url, formMascota, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        setFormMascota(response.data);
        peticionGetMascota();
        setToggleSpinner(false);
        setToggleAlert({
          isOpen: true,
          message: " Mascota agregada correctamente",
          type: "success",
        });
        // setCardFormGuardar(false);
        setTipoModal("actualizar");
      })
      .catch((error) => {
        setToggleAlert({
          isOpen: true,
          message: " Error," + error.message,
          type: "danger",
        });
        setToggleSpinner(false);
      });
  };

  const peticionPut = async () => {
    await axios
      .put(formMascota.url, formMascota, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        peticionGetMascota();
        setToggleSpinner(false);
        setFormMascota(response.data);
        setToggleAlert({
          isOpen: true,
          message: " Mascota actualizada correctamente",
          type: "success",
        });
        // setCardFormGuardar(false);
      })
      .catch((error) => {
        setToggleAlert({
          isOpen: true,
          message: " Error," + error.message,
          type: "danger",
        });
        setToggleSpinner(false);
      });
  };

  const handleChange = async (e) => {
    e.persist();
    await setFormMascota({
      ...formMascota,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (selectedDay == "Invalid Date" && formMascota.id == "") {
      setToggleAlert({
        isOpen: true,
        message: " Ingrese una fecha de vacunación correcta",
        type: "danger",
      });
    } else {
      setToggleSpinner(true);
      if (tipoModal === "insertar") {
        peticionPost();
      } else {
        peticionPut();
      }
    }
  };

  const sumDay = () => {
    var date = new Date(formMascota.fecha_vacuna);
    date.setDate(date.getDate() + 1);
    return date;
  };

  const seleccionarObjetoEliminar = () => {
    setModalFormEliminar(true);
  };

  const handleDayChange = (selectedDayInto, modifiers, dayPickerInput) => {
    // const input = dayPickerInput.getInput();
    if (selectedDayInto) {
      setSelectedDay(selectedDayInto);
      setFormMascota({
        ...formMascota,
        fecha_vacuna: selectedDayInto.toISOString().substring(0, 10),
      });
    } else {
      setSelectedDay(undefined);
    }
  };


    const cerrarForm = () => {
    setCardFormGuardar(false);

    if (cardFormGuardar) {
      setColorBotton("success");
    } else {
      setColorBotton("warning");
    }

    setFormMascota({
      id: "",
      url: "",
      propietario: formMascota.url,
      nombre: "",
      sexo: "1",
      color: "",
      raza: "",
      edad: "",
      vacuna: "",
      fecha_vacuna: "",
      dosis: "",
      establecimiento: "",
      ris_id: "",
      valor: "",
    });
  };

  useEffect(() => {
    peticionGetVacuna();
    peticionGetEstablecimiento();
    setSelectedDay(sumDay());
   
  }, []);

  return (
    <>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={modalFormEliminar}
        toggle={() => setModalFormEliminar(!modalFormEliminar)}
      >
        {modalFormEliminar ? (
          <CardEliminarMascota
            modalFormEliminar={modalFormEliminar}
            setModalFormEliminar={setModalFormEliminar}
            peticionGetMascota={peticionGetMascota}
            formMascota={formMascota}
            setFormMascota={setFormMascota}
            cardFormGuardar={cardFormGuardar}
            setCardFormGuardar={setCardFormGuardar}
          />
        ) : null}
      </Modal>

      <Card style={{ border: "1px solid #3719b3", marginBottom: "5px" }}>
        <CardHeader style={{ paddingBottom: 2 }}>
          Datos de la mascota{" "}
          <span className="text-danger text-uppercase" style={{fontSize:"20px", fontWeight:"bold"}}>(
            {tipoModal === "actualizar" ? "EDITAR DATOS" : "NUEVO"}
            )</span>
          <h4 className="text-muted">
            {" "}
            <strong>{formMascota["ris_nombre"]}</strong>
          </h4>
        </CardHeader>

        <CardBody style={{ paddingBottom: "0" }}>
          <Form
            role="form"
            onSubmit={submitForm}
            method="post"
            encType="multipart/form-data"
          >
            <div className="pl-lg-0">
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="nombre">
                      Nombre <span className="text-danger"> * </span>
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="nombre"
                      disabled={
                        data_user.ris !== formMascota["ris_id"] &&
                        formMascota["ris_id"]
                      }
                      name="nombre"
                      placeholder=""
                      required="required"
                      value={formMascota["nombre"]}
                      onChange={handleChange}
                      type="text"
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="nombre">
                      Sexo <span className="text-danger"> * </span>
                    </label>

                    <div className="custom-control custom-radio ">
                      <input
                        className="custom-control-input"
                        id="macho"
                        disabled={
                          data_user.ris !== formMascota["ris_id"] &&
                          formMascota["ris_id"]
                        }
                        name="sexo"
                        type="radio"
                        value="1"
                        checked={
                          formMascota["sexo"] === "1" ||
                          formMascota["sexo"] === 1
                        }
                        required="required"
                        onChange={handleChange}
                      />
                      <label className="custom-control-label" htmlFor="macho">
                        Macho
                      </label>
                    </div>

                    <div className="custom-control custom-radio ">
                      <input
                        className="custom-control-input"
                        id="hembra"
                        disabled={
                          data_user.ris !== formMascota["ris_id"] &&
                          formMascota["ris_id"]
                        }
                        name="sexo"
                        type="radio"
                        value="2"
                        checked={
                          formMascota["sexo"] === "2" ||
                          formMascota["sexo"] === 2
                        }
                        onChange={handleChange}
                      />
                      <label className="custom-control-label" htmlFor="hembra">
                        Hembra
                      </label>
                    </div>
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="color">
                      Color
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="color"
                      disabled={
                        data_user.ris !== formMascota["ris_id"] &&
                        formMascota["ris_id"]
                      }
                      name="color"
                      placeholder=""
                      value={formMascota["color"]}
                      onChange={handleChange}
                      type="color"
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="color">
                      Raza
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="raza"
                      disabled={
                        data_user.ris !== formMascota["ris_id"] &&
                        formMascota["ris_id"]
                      }
                      name="raza"
                      placeholder=""
                      value={formMascota["raza"]}
                      onChange={handleChange}
                      type="text"
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>

              {formMascota.id ? null : (
                <Row>
                  <Col md="12">
                    <h3 className="text-info">Primer registro de vacuna:</h3>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="edad">
                        Edad (Meses) <span className="text-danger"> * </span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="edad"
                        disabled={
                          data_user.ris !== formMascota["ris_id"] &&
                          formMascota["ris_id"]
                        }
                        name="edad"
                        placeholder=""
                        required="required"
                        value={formMascota["edad"]}
                        onChange={handleChange}
                        type="number"
                      ></Input>
                    </FormGroup>
                  </Col>

                  <Col md="8">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="vacuna">
                        Vacuna <span className="text-danger"> * </span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="vacuna"
                        disabled={
                          data_user.ris !== formMascota["ris_id"] &&
                          formMascota["ris_id"]
                        }
                        name="vacuna"
                        required="required"
                        value={formMascota["vacuna"]}
                        onChange={handleChange}
                        type="select"
                      >
                        <option value=""> -- -- </option>
                        {dataVacuna.map((object) => (
                          <option key={object.id} value={object.url}>
                            {object.nombre} (Lote: {object.lote}){" "}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="fecha_vacuna"
                      >
                        Fecha Vacunación{" "}
                        <span className="text-danger"> * </span>
                      </label>

                      <DayPickerInput
                        required={true}
                        readOnly={true}
                        value={selectedDay}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        placeholder={`${formatDate(new Date(), "LL", "es")}`}
                        dayPickerProps={{
                          locale: "es",
                          localeUtils: MomentLocaleUtils,
                          initialMonth: new Date(2023, 9),
                          disabledDays: {
                            before: new Date(2022, 7, 1),
                            after: new Date(),
                          },
                        }}
                        onDayChange={handleDayChange}
                        inputProps={{
                          readOnly: true,
                          required: true,
                          className: "form-control-alternative",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="dosis">
                        Número de vacuna{" "}
                        <span className="text-danger"> * </span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="dosis"
                        disabled={
                          data_user.ris !== formMascota["ris_id"] &&
                          formMascota["ris_id"]
                        }
                        name="dosis"
                        placeholder=""
                        required="required"
                        value={formMascota["dosis"]}
                        onChange={handleChange}
                        type="number"
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="establecimiento"
                      >
                        Establecimiento.{" "}
                        <span className="text-danger"> * </span>
                      </label>

                      {data_user.ris !== formMascota["ris_id"] &&
                      formMascota["ris_id"] ? (
                        <Input
                          className="form-control-alternative"
                          id="establecimiento"
                          name="establecimiento"
                          placeholder=""
                          disabled="true"
                          required="required"
                          value=" -- "
                          onChange={handleChange}
                          type="input"
                        />
                      ) : (
                        <Input
                          className="form-control-alternative"
                          id="establecimiento"
                          name="establecimiento"
                          placeholder=""
                          required="required"
                          value={formMascota["establecimiento"]}
                          onChange={handleChange}
                          type="select"
                        >
                          <option value=""> -- -- </option>
                          {dataEstablecimiento.map((object) => (
                            <option key={object.id} value={object.url}>
                              {object.nombre}
                            </option>
                          ))}
                        </Input>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </div>

            {data_user.ris === formMascota["ris_id"] ? (
              <div className="text-center">
                 <Button className="my-4" color="dark" onClick={() => cerrarForm()}>
                      Cerrar
                    </Button>
                {tipoModal === "actualizar" ? (
                  <Button className="my-4" color="primary" type="submit">
                    Actualizar mascota
                  </Button>
                ) : (
                  <Button className="my-4" color="primary" type="submit">
                  Agregar mascota
                  </Button>
                )}

                {tipoModal === "actualizar" ? (
                  <Button
                    className="my-4"
                    color="danger"
                    type="button"
                    onClick={() => seleccionarObjetoEliminar()}
                  >
                    Eliminar
                  </Button>
                ) : null}
              </div>
            ) : (
              <>
                {tipoModal === "insertar" ? (
                  <div className="text-center">
                     <Button className="my-4" color="dark" onClick={() => cerrarForm()}>
                      Cerrar
                    </Button>
                    <Button className="my-4" color="primary" type="submit">
                      Agregar mascota
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </Form>
        </CardBody>
      </Card>

      {formMascota.id ? (
        <Row>
          <Col md="">
            <ListaDosis
              dataVacuna={dataVacuna}
              formMascota={formMascota}
            ></ListaDosis>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default CardMascota;
