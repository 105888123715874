import React, { Fragment } from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  NavLink,
} from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import AuthProvider from "context/auth/Provider";

const Main = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    console.log(" -->> ");
    // document.body.classList.add("bg-default");
    // return () => {
    //     document.body.classList.remove("bg-default");
    // };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/main") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header  py-1 py-lg-5">
          <Container>
            <div className="header-body text-center mb-7">


              <Row className="justify-content-center">
                {/* <Col lg="5" md="6">

                                    <h1 className="text-white">DIRIS LIMA SUR</h1>
                                    <p className="text-lead text-light">
                                        Sistema para Vacunas Caninas
                                    </p>
                                </Col> */}
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <AuthProvider>
          <Fragment>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/main/index" />
            </Switch>
          </Fragment>
        </AuthProvider>

        <Container>
          <Row className="align-items-center text-center">
            <Col xl="12">
              <NavLink to="/auth/login" className="btn btn-warning">
                Ingresar al sistema
              </NavLink>
            </Col>
          </Row>
        </Container>
      </div>

      <AuthFooter />
    </>
  );
};

export default Main;
