import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import AuthContext from "context/auth";
import { useContext } from "react";
import { Button, Card, CardBody, CardHeader, Modal, Table } from "reactstrap";
import CardDosis from "./CardDosis";
import CardEliminarDosis from "./CardEliminarDosis";

const ListaDosis = ({ setCardFormGuardar, dataVacuna, formMascota }) => {
  const data_user = useMemo(
    () => JSON.parse(localStorage.getItem("@diris_ls-data")),
    []
  );

  const { token, url_main } = useContext(AuthContext);
  let url_dosis = url_main + "api/dosis/";
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [tipoModal, setTipoModal] = useState("insertar");
  const [modalFormGuardar, setModalFormGuardar] = useState(false);

  const [dosisLista, setDosisLista] = useState([]);

  const [formDosis, setFormDosis] = useState({
    id: "",
    url: "",
    mascota: formMascota.url,
    edad: "",
    fecha: "",
    dosis: "",
    vacuna: "",
    establecimiento: "",
  });

  const [modalFormEliminar, setModalFormEliminar] = useState(false);

  const getListaDosis = async () => {
    await axios
      .get(url_dosis + "?mascota=" + formMascota.id, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        setDosisLista(response.data.results);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const nuevaDosis = async () => {
    setTipoModal("insertar");
    setFormDosis({
      id: "",
      url: "",
      mascota: formMascota.url,
      edad: "",
      fecha: "",
      dosis: "",
      vacuna: "",
      establecimiento: "",
    });
    setModalFormGuardar(true);
  };

  const seleccionarDosis = (objecto) => {
    setFormDosis({
      id: "",
      url: "",
      mascota: formMascota.url,
      edad: "",
      fecha: "",
      dosis: "",
      vacuna: "",
      establecimiento: "",
    });

    setTimeout(
      function () {
        //Start the timer
        setTipoModal("actualizar");
        setFormDosis(objecto);
        setModalFormGuardar(true);
      }.bind(this),
      300
    );
  };

  const seleccionarObjetoEliminar = (objecto) => {
    setFormDosis({
      id: "",
      url: "",
      mascota: formMascota.url,
      edad: "",
      fecha: "",
      dosis: "",
      vacuna: "",
      establecimiento: "",
    });

    setTimeout(
      function () {
        setFormDosis(objecto);
        setModalFormEliminar(true);
      }.bind(this),
      300
    );
  };

  useEffect(() => {
    getListaDosis();
  }, []);

  return (
    <>
      <Card style={{ border: "1px solid #3719b3" }}>
        <CardHeader>
          <h3 className="text-info">
            Vacunas {" "}
            {data_user.ris == formMascota["ris_id"] ? (
              <Button
                color="warning"
                onClick={() => nuevaDosis()}
                style={{ float: "right" }}
              >
                <i className="ni ni-fat-add " />
              </Button>
            ) : null}
          </h3>
        </CardHeader>

        <CardBody>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">N° Vacuna</th>
                <th scope="col">Fecha</th>
                <th scope="col">OPC</th>
              </tr>
            </thead>
            <tbody>
              {dosisLista.map((object) => (
                <tr key={object.id}>
                  <td>{object.dosis}</td>
                  <td>{object.fecha}</td>
                  <td>
                    {data_user.ris == formMascota["ris_id"] ? (
                      <>
                        <Button
                          color="success"
                          size="sm"
                          onClick={() => seleccionarDosis(object)}>
                          Editar
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => seleccionarObjetoEliminar(object)}>
                          Quitar
                        </Button>
                      </>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Modal
        className="modal-dialog-centered  "
        size="lg"
        isOpen={modalFormGuardar}
        // toggle={() => setModalFormGuardar(!modalFormGuardar)}
      >
        {modalFormGuardar ? (
          <CardDosis
            setCardFormGuardar={setCardFormGuardar}
            formMascota={formMascota}
            dataVacuna={dataVacuna}
            getListaDosis={getListaDosis}
            setModalFormGuardar={setModalFormGuardar}
            formDosis={formDosis}
            setFormDosis={setFormDosis}
            alertSuccess={alertSuccess}
            setAlertSuccess={setAlertSuccess}
            tipoModal={tipoModal}
            setTipoModal={setTipoModal}
            dosisLista={dosisLista}
          />
        ) : null}
      </Modal>

      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={modalFormEliminar}
        toggle={() => setModalFormEliminar(!modalFormEliminar)}
      >
        {modalFormEliminar ? (
          <CardEliminarDosis
            setModalFormEliminar={setModalFormEliminar}
            getListaDosis={getListaDosis}
            formDosis={formDosis}
          />
        ) : null}
      </Modal>
    </>
  );
};
export default ListaDosis;
