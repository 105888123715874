import axios from "axios";
import AuthContext from "context/auth";
import React, { useContext, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, Modal, Row, Spinner, Table } from "reactstrap";
import ReactPaginate from 'react-paginate';
import fileDownload from "js-file-download";



const ListaMascotaDigitador = () => {

    const { token, url_main } = useContext(AuthContext);
    const data_user = useMemo(() => JSON.parse(localStorage.getItem("@diris_ls-data")), []);

    let url = url_main + "api/dosis/";
    let url_excel_general = url_main + "mascota/generar-excel-mascota-usuario/";

    let pagination = 30;
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [pageCount, setPageCount] = useState(2);
    const [currentPage, setCurrentPage] = useState(1);
    const [spinner, setSpinner] = useState(true);
    const [formBuscar, setFormBuscar] = useState({
        fecha_inicio: '',
        fecha_fin: '',
    });

    const peticionGet = (page, query) => {
        axios.get(url + "?page=" + page + "&usuario=" + data_user.username
            + query, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            setData(response.data.results);
            setCount(response.data.count);
            setPageCount(Math.ceil(response.data.count / pagination));
            setSpinner(false);
        }).catch(error => {
            console.log(error.message);
            setSpinner(false);
        });
    }



    const handlePageClick = (data) => {
        let selected = data.selected;
        setCurrentPage(selected + 1)
    };


    const handleChangeFormBuscar = async e => {
        e.persist();
        await setFormBuscar({
            ...formBuscar,
            [e.target.name]: e.target.value,
        });
    }

    const submitBuscarForm = e => {
        setSpinner(true);
        e.preventDefault();
        var query = "&fecha_inicio=" + formBuscar.fecha_inicio +
            "&fecha_fin=" + formBuscar.fecha_fin;
        peticionGet(currentPage, query);

    }

    const changePage = () => {
        var query = "&fecha_inicio=" + formBuscar.fecha_inicio +
            "&fecha_fin=" + formBuscar.fecha_fin;
        peticionGet(currentPage, query);
    };


    useEffect(() => {
        peticionGet(currentPage, "");
    }, []);

    useEffect(changePage, [currentPage]);


    const MostrarTodo = e => {
        setSpinner(true);
        peticionGet(1, "");
        setFormBuscar({
            fecha_inicio: '',
            fecha_fin: '',
        });
    }




    const handleDownload = (url, filename) => {
        setSpinner(true);

        var query = "?fecha_inicio=" + formBuscar.fecha_inicio +
            "&fecha_fin=" + formBuscar.fecha_fin;

        axios.get(url + query, {
            responseType: 'blob',
            headers: {
                'Authorization': `token ${token}`
            }
        }).then((res) => {
                fileDownload(res.data, filename)
                setSpinner(false);});
                
    }


    return (<>


        <Modal
            className="modal-dialog-centered modal-lg"
            isOpen={spinner}>
            <div className="modal-body text-center">
                <Spinner className="spinners" type="grow" color="danger" style={{ width: '5rem', height: '5rem' }} />
                <p>
                    Procesando información...
                </p>
            </div>
        </Modal>

        <div className="header bg-gradient-default pb-8 pt-0 pt-md-6">
            <Container fluid>
                <div className="header-body">
                    {/* Card stats */}
                    <Row >
                        <Col lg="6" xl="3">
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <h1 className="text-info">TOTAL: {count}</h1>
                            <h3 className="mb-0">Lista de Vacunas <small> Administrados por: {data_user?.nombre_completo}</small></h3>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-2">

                            <Form onSubmit={submitBuscarForm} >
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="fecha_inicio">
                                                Fecha
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="fecha_inicio"
                                                name="fecha_inicio"
                                                placeholder=""
                                                required="required"
                                                value={formBuscar["fecha_inicio"]}
                                                onChange={handleChangeFormBuscar}
                                                type="date" />
                                        </FormGroup>
                                    </Col>

                                    <Col md="2">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="fecha_fin">
                                                Fecha fin
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="fecha_fin"
                                                name="fecha_fin"
                                                placeholder=""
                                                required="required"
                                                value={formBuscar["fecha_fin"]}
                                                onChange={handleChangeFormBuscar}
                                                type="date" />
                                        </FormGroup>
                                    </Col>

                                    <Col md="2">
                                        <FormGroup>
                                            <label
                                                className="form-control-label text-white"
                                                htmlFor="nombres">
                                                Buscar
                                            </label>
                                            <Input
                                                className="form-control-alternative btn-info"
                                                placeholder=""
                                                value="Buscar"
                                                type="submit" >
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md="2">
                                        <FormGroup>
                                            <label
                                                className="form-control-label text-white"
                                                htmlFor="nombres">
                                                Mostrar todo
                                            </label>
                                            <Input
                                                className="form-control-alternative btn-warning"
                                                placeholder=""
                                                value="Mostrar todo"
                                                onClick={() => MostrarTodo()}
                                                type="button" />
                                        </FormGroup>
                                    </Col>
                                    {count?
                                        <Col md="2">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label text-white"
                                                    htmlFor="nombres">
                                                    Generar excel
                                                </label>
                                                <Input
                                                    className="form-control-alternative btn-success"
                                                    placeholder=""
                                                    value="Generar Excel"
                                                    onClick={() => {
                                                        handleDownload(url_excel_general, 'lista_mascotas.xlsx')
                                                    }}
                                                    type="button" >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    :null}
                                </Row>
                            </Form>


                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Propietario</th>
                                        <th scope="col">Código</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Raza</th>
                                        <th scope="col">Fecha Vac.</th>
                                        {/* <th scope="col">OPC</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map((object, index) => (
                                            <tr key={object.id}>
                                                <th scope="row">
                                                    {currentPage > 1 ?
                                                        1 + index + (currentPage - 1) * pagination
                                                        : index + currentPage}
                                                </th>
                                                <td>{object.mascota_object.propietario_object.nombre_completo}</td>
                                                <td>{object.mascota_object.codigo}</td>
                                                <td>{object.mascota_object.nombre}</td>
                                                <td>{object.mascota_object.raza}</td>
                                                <td>{object.fecha}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>

                        </CardBody>
                        <CardFooter>

                            <nav aria-label="Page navigation example">
                                <nav className="pagination justify-content-center" aria-label="pagination">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={'justify-content-center pagination'}
                                        activeClassName={'active'}
                                        pageLinkClassName={'page-link'}
                                        pageClassName={'page-item'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                    />
                                </nav>
                            </nav>
                        </CardFooter>
                    </Card>
                </div>
            </Row>
        </Container>

    </>);
}

export default ListaMascotaDigitador;