import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Modal, Row, Spinner, UncontrolledAlert } from "reactstrap";
import axios from 'axios';
import ListaMascota from "views/mascota/listaMascota";
import { useContext } from "react";
import AuthContext from "context/auth";


const CardPersona = ({ modalFormGuardar, setModalFormGuardar, peticionGetPerson, tipoModalPersona, formPersona, setFormPersona, currentPage, setTipoModalPersona }) => {

    const { token, url_main , setToggleAlert, setToggleSpinner } = useContext(AuthContext);

    let url = url_main + "api/propietarios/";
    let url_persona_reniec = url_main + 'api/persona-reniec/';

    

    const peticionGetValid = async (tipo_documento, documento) => {

        var valid = true;

        await axios.get(url + "?tipo_documento="+tipo_documento+"&numero_documento=" + documento, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            //   this.peticionGet();
            if (response.data.results.length > 0) {
                valid = false;
                setFormPersona(response.data.results[0]);
                setToggleSpinner(false);
            }else{
                peticionGetReniecValid(formPersona.numero_documento);
            }
            
        }).catch(error => {
            
            setToggleSpinner(false);
        });

        return valid
    };



    const peticionGetReniecValid = async (dni) => {
        setToggleSpinner(true);
        await axios.get(url_persona_reniec + dni, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {

            if (response.data) {
                setFormPersona({
                    tipo_documento: formPersona.tipo_documento,
                    numero_documento: formPersona.numero_documento,
                    nombres: response.data.nombres,
                    apellido_materno: response.data.apellido_materno,
                    apellido_paterno: response.data.apellido_paterno,
                    direccion: response.data.direccion,
                })
            }
            setToggleSpinner(false);

        }).catch(error => {
            setToggleSpinner(false);
            setToggleAlert({
                isOpen: true,
                message: " Error,"+error.message,
                type: "danger",
              });
        });
    };


    const validarReniec = () => {
        setToggleSpinner(true);
        peticionGetValid(formPersona.tipo_documento,formPersona.numero_documento);
    }

    const peticionPost = async () => {
        delete formPersona.id;
        await axios.post(url, formPersona, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            peticionGetPerson(currentPage);
            setToggleSpinner(false);
            setFormPersona(response.data);
            setToggleAlert({
                isOpen: true,
                message: " Propietario agregado correctamente",
                type: "success",
              });
            setTipoModalPersona('actualizar');
        }).catch(error => {
            setToggleAlert({
                isOpen: true,
                message: " Error,"+error.message,
                type: "danger",
              });
            setToggleSpinner(false);
        });
    }

    const peticionPut = async () => {
        await axios.put(formPersona.url, formPersona, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            peticionGetPerson(currentPage);
            setToggleSpinner(false);
            setFormPersona(response.data);
            setToggleAlert({
                isOpen: true,
                message: " Propietario actualizado correctamente",
                type: "success",
              });
        }).catch(error => {
            setToggleAlert({
                isOpen: true,
                message: " Error,"+error.message,
                type: "danger",
              });
            setToggleSpinner(false);
        });
    }


    const handleChange = async e => {
        e.persist();
        await setFormPersona({
            ...formPersona,
            [e.target.name]: e.target.value,
        }
        );
    }

    const submitForm = e => {
        setToggleSpinner(true);
        e.preventDefault();

        if (formPersona.id){
            peticionPut();
        }else{
            peticionPost();
        }
    }



    return (<>

        <div className="modal-header">
            <h3 className="modal-title" id="modal-title-notification">
                Guardar Propietario y sus Mascotas
            </h3>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalFormGuardar(!modalFormGuardar)}>
                <span aria-hidden={true}>×</span>
            </button>
        </div>
        <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">


                <CardBody className="px-lg-5 py-lg-5">
                    <Row>
                        <Col className="col-md-6 col-12">
                            <Card>
                                <CardHeader>Datos del propietario</CardHeader>
                                <CardBody>
                                    <Form role="form"
                                        onSubmit={submitForm}
                                        method="post"
                                        encType="multipart/form-data">

                                        <div className="pl-lg-0">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="tipo_documento">
                                                            Tipo de Doc. <span className="text-danger" > * </span>
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="tipo_documento"
                                                            name="tipo_documento"
                                                            placeholder=""
                                                            value={formPersona["tipo_documento"]}
                                                            onChange={handleChange}
                                                            type="select">
                                                            <option value="1">D.N.I.</option>
                                                            <option value="2">Carné de extrangería</option>
                                                            <option value="3">Pasaporte</option>
                                                            <option value="5">A.S.</option>
                                                            <option value="6">C.I.</option>
                                                            <option value="7">P.T.P.</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>


                                                <Col md="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="numero_documento">
                                                            Documento <span className="text-danger" > * </span>
                                                        </label>



                                                        {tipoModalPersona === "insertar" ?
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="numero_documento"
                                                                name="numero_documento"
                                                                placeholder=""
                                                                required="required"
                                                                value={formPersona["numero_documento"]}
                                                                onChange={handleChange}
                                                                type="text" /> :

                                                            <Input
                                                                style={{ cursor: 'not-allowed' }}
                                                                className="form-control-alternative"
                                                                id="numero_documento"
                                                                name="numero_documento"
                                                                placeholder=""
                                                                required="required"
                                                                disabled="disabled"
                                                                value={formPersona["numero_documento"]}
                                                                type="text" />
                                                        }
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup>
                                                        {tipoModalPersona === "insertar" ?
                                                            <Button color="success"
                                                                type="button"
                                                                className="mt-4 btn-block"
                                                                onClick={() => { validarReniec() }}>
                                                                Buscar
                                                            </Button> 
                                                            : null
                                                        }

                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="nombres">
                                                            Nombres <span className="text-danger" > * </span>
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="nombres"
                                                            name="nombres"
                                                            placeholder=""
                                                            required="required"
                                                            value={formPersona["nombres"]}
                                                            onChange={handleChange}
                                                            type="text" />
                                                    </FormGroup>
                                                </Col>


                                                <Col md="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="apellido_paterno">
                                                            Apellido paterno <span className="text-danger" > * </span>
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="apellido_paterno"
                                                            name="apellido_paterno"
                                                            placeholder=""
                                                            required="required"
                                                            value={formPersona["apellido_paterno"]}
                                                            onChange={handleChange}
                                                            type="text" />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="apellido_materno">
                                                            Apellido materno <span className="text-danger" > * </span>
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="apellido_materno"
                                                            name="apellido_materno"
                                                            placeholder=""
                                                            required="required"
                                                            value={formPersona["apellido_materno"]}
                                                            onChange={handleChange}
                                                            type="text" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="telefono">
                                                            Teléfono
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="telefono"
                                                            name="telefono"
                                                            placeholder=""
                                                            value={formPersona["telefono"]}
                                                            onChange={handleChange}
                                                            type="text" />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="direccion">
                                                            Dirección
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="direccion"
                                                            name="direccion"
                                                            placeholder=""
                                                            value={formPersona["direccion"]}
                                                            onChange={handleChange}
                                                            type="textarea" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="text-center">
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="submit"
                                            >
                                                Guardar
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col className="col-md-6 col-12">
                            {formPersona.id ?
                                <ListaMascota propietario={formPersona} />
                                : null
                            }
                        </Col>

                    </Row>

                </CardBody>
            </Card>
        </div>
    </>);
}

export default CardPersona;