import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import AuthContext from "context/auth";
import axios from "axios";
import QRCode from "react-qr-code";
import fileDownload from "js-file-download";

const Mascota = () => {
  const { url_main } = useContext(AuthContext);
  const { codigo } = useParams();

  let url = url_main + "api/consulta-credencial-mascota/" + codigo;
  let url_credencial_pdf =
    url_main + "mascota/generar-credencial-pdf/" + codigo;
  let value_qr =
    "https://vancan.dirislimasur.gob.pe/credencial/mascota/" + codigo;

  const [mascota, setMascota] = useState({
    foto: "",
    codigo: "",
    nombre: "",
    edad: "",
    sexo_nombre: "",
    raza: "",

    dosis: "",
    vacuna_fecha_format: "",
    establecimiento_object: {
      nombre: "",
    },
    vacuna_object: {
      nombre: "",
    },

    propietario_object: {
      numero_documento: "",
      nombre_completo: "",
      direccion: "",
      telefono: "",
    },

    dosis_lista: [],
  });
  const [spinner, setSpinner] = useState(false);

  const pdfDownload = () => {
    setSpinner(true);
    axios
      .get(url_credencial_pdf, {
        responseType: "blob",
        headers: {},
      })
      .then((res) => {
        fileDownload(res.data, "credencial.pdf");
        setSpinner(false);
      });
  };

  const peticionGet = async () => {
    await axios
      .get(url, {})
      .then((response) => {
        setMascota(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <>
      <Modal className="modal-dialog-centered modal-lg" isOpen={spinner}>
        <div className="modal-body text-center">
          <Spinner
            className="spinners"
            type="grow"
            color="danger"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>Procesando información...</p>
        </div>
      </Modal>

      <Container
        className="bg-gradient-danger pl-lx-6 p-md-0 p-xl-5"
        style={{ paddingTop: "40px" }}
      >
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        style={{
                          maxWidth: "222px",
                          maxHeight: "222px",
                          width: "222px",
                          height: "222px",
                        }}
                        alt="..."
                        className="rounded-circle"
                        src={
                          mascota.foto
                            ? mascota.foto
                            : require("../../assets/img/brand/perro.jpg")
                                .default
                        }
                      />
                    </a>
                  </div>
                </Col>
              </Row>

              <CardHeader className="text-center border-0 pt-8 pt-md-0 pb-0 pb-md-0 title-card-desktop">
                <div className="d-flex justify-content-between mt-3">
                  <QRCode
                    value={value_qr}
                    title="sdsd"
                    size={180}
                    fgColor="#f5365c"
                    level="L"
                  />
                  <br />
                  <h1>{mascota.codigo}</h1>
                </div>
              </CardHeader>

              <CardBody className="pt-0 pt-md-0">
                <CardHeader className="title-card-mobile mt-5">
                  <div className="col-12 text-center mt-8 ">
                    {/* <QRCode value={value_qr} title="sdsd" size={180} fgColor="#f5365c" level="L" /><br/> */}
                  </div>
                  <div className="col-12 text-center ">
                    <h1>{mascota.codigo}</h1>
                  </div>
                </CardHeader>

                <Row className="title-card-desktop text-center">
                  <h1 className="text-strong">
                    <Badge
                      className="badge-default h1 text-uppercase"
                      pill
                      style={{ fontSize: "40px" }}
                    >
                      {mascota.nombre}
                    </Badge>
                  </h1>

                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-0">
                      <div>
                        <span className="heading" style={{ fontSize: "30px" }}>
                          {mascota.get_edad ? mascota.get_edad : "-"}
                        </span>
                        <span
                          className="description"
                          style={{ fontSize: "20px" }}
                        >
                          Meses
                        </span>
                      </div>
                      <div>
                        <span className="heading" style={{ fontSize: "30px" }}>
                          {mascota.sexo_nombre ? mascota.sexo_nombre : "-"}
                        </span>
                        <span
                          className="description"
                          style={{ fontSize: "20px" }}
                        >
                          Sexo
                        </span>
                      </div>
                      <div>
                        <span className="heading" style={{ fontSize: "30px" }}>
                          {mascota.raza ? mascota.raza : "-"}
                        </span>
                        <span
                          className="description"
                          style={{ fontSize: "20px" }}
                        >
                          Raza
                        </span>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row className="title-card-mobile text-center">
                  <h1 className="text-strong">
                    <Badge
                      className="badge-default h1 text-uppercase"
                      pill
                      style={{ fontSize: "40px" }}
                    >
                      {mascota.nombre}
                    </Badge>
                  </h1>

                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-0">
                      <div>
                        <span className="heading" style={{ fontSize: "20px" }}>
                          {mascota.get_edad ? mascota.get_edad : "-"}
                        </span>
                        <span
                          className="description"
                          style={{ fontSize: "15px" }}
                        >
                          Meses
                        </span>
                      </div>
                      <div>
                        <span className="heading" style={{ fontSize: "20px" }}>
                          {mascota.sexo_nombre ? mascota.sexo_nombre : "-"}
                        </span>
                        <span
                          className="description"
                          style={{ fontSize: "15px" }}
                        >
                          Sexo
                        </span>
                      </div>
                      <div>
                        <span className="heading" style={{ fontSize: "20px" }}>
                          {mascota.raza ? mascota.raza : "-"}
                        </span>
                        <span
                          className="description"
                          style={{ fontSize: "15px" }}
                        >
                          Raza
                        </span>
                      </div>
                    </div>
                  </div>
                </Row>

                <div className="text-center">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-0 row">
                        <div className="col-md12">
                          <span
                            className="heading"
                            style={{ fontSize: "20px" }}
                          >
                            Propietario:
                          </span>
                          <span
                            className="description"
                            style={{ fontSize: "15px" }}
                          >
                            {mascota.propietario_object.nombre_completo}
                          </span>{" "}
                          <br />
                          <span
                            className="description"
                            style={{ fontSize: "15px" }}
                          >
                            {mascota.propietario_object.numero_documento}
                          </span>
                        </div>
                        <div className="col-md12">
                          <span
                            className="heading"
                            style={{ fontSize: "20px" }}
                          >
                            Contacto:
                          </span>
                          <span
                            className="description"
                            style={{ fontSize: "15px" }}
                          >
                            Cel.{" "}
                            {mascota.propietario_object.telefono
                              ? mascota.propietario_object.telefono
                              : " -- "}
                          </span>
                          <br />
                          <span
                            className="description"
                            style={{ fontSize: "15px" }}
                          >
                            {mascota.propietario_object.direccion}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <hr className="my-1" />

                  {mascota.dosis_lista.map((object) => (
 
                    <Card className="card-stats mb-4 mb-lg-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0">
                              <span className="h1 text-danger">
                                VACUNA DOSIS {object.dosis}{" "}
                              </span>{" "}
                              <br />
                              <span className="h2 font-weight-bold mb-0">
                                Fecha: {object.fecha_format}
                              </span>
                            </CardTitle>
                            <blockquote className="blockquote">
                              <p className="mb-0">
                                {object.vacuna_object.nombre}
                              </p>
                              <footer className="blockquote-footer">
                                DIRIS LIMA SUR{" "}
                                <cite title="Source Title">
                                  {" "}
                                  - {object.establecimiento_object.nombre}
                                </cite>
                              </footer>
                            </blockquote>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  ))}

                  <button
                    onClick={() => pdfDownload()}
                    className="btn btn-info mt-4"
                  >
                    Descargar PDF
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Mascota;
