import React, { Fragment } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import AuthProvider from "context/auth/Provider";

const Credencial = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/credencial") {
        return (
          <Route
            path={prop.layout + prop.path + "/:codigo"}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        {/* <AuthNavbar /> */}
        <div className="header  py-1 py-lg-5">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                {/* <Col lg="5" md="6">

                                    <h1 className="text-white">DIRIS LIMA SUR</h1>
                                    <p className="text-lead text-light">
                                        Sistema para Vacunas Caninas
                                    </p>
                                </Col> */}
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <AuthProvider>
          <Fragment>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/credencial/mascota" />
            </Switch>
          </Fragment>
        </AuthProvider>
      </div>

      <AuthFooter />
    </>
  );
};

export default Credencial;
