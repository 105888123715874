import axios from "axios";
import AuthContext from "context/auth";
import React, { useContext, useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle, Col, Container, Modal, Row } from "reactstrap";
import CardMascotaPropietario from "./CardMascotaPropietario";

const ListaMascotaPropietario = () => {


    const { token, url_main } = useContext(AuthContext);

    let url = url_main + "api/mascotas-update/";
    const [data, setData] = useState([]);
    const [modalFormGuardar, setModalFormGuardar] = useState(false);

    const [formMascota, setFormMascota] = useState({
        id: '',
        foto:'',
        url: '',
        nombre: '',
        sexo: '',
        color: '',
        raza: '',
        edad: '',
        fecha_vacuna:'',
        propietario:'',
        hobbies:'',
        alimentacion:'',
        medicamentos:'',
        perdido:'',
        aviso_busqueda:'',
    });

    const peticionGet =  async () => {
        axios.get(url, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            setData(response.data.results);
            console.log(" Response ");
            console.log(response.data);
        }).catch(error => {
            console.log(error.message);
        });
    }


    const selecionarMascota = (object) =>{
        console.log(" --->>>  object");
        console.log(object);
        setFormMascota(object);
        setModalFormGuardar(!modalFormGuardar);

    }

    useEffect(() => {
        peticionGet();
    },[]);


    return (<>


            <Modal
                className="modal-dialog-centered  "
                size="xl"
                isOpen={modalFormGuardar}
                toggle={() => setModalFormGuardar(!modalFormGuardar)}>
                {
                    modalFormGuardar ? <CardMascotaPropietario 
                                        modalFormGuardar={modalFormGuardar}
                                        setModalFormGuardar={setModalFormGuardar}
                                        formMascota={formMascota}
                                        setFormMascota={setFormMascota}
                                        peticionGet={peticionGet}
                                        />
                        : null
                }
            </Modal>


            <div className="header bg-gradient-default pb-8 pt-0 pt-md-6">
                <Container fluid>
                    <div className="header-body">
                        {/* Card stats */}
                        <Row >
                            <Col lg="6" xl="3">
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>


        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow">

                        <CardHeader className="border-0">
                            <h3 className="mb-0">Lista de Mascotas <small></small></h3> 
                        </CardHeader>

                        <CardBody className="px-lg-5 py-lg-2">
                            <Row>


                                {
                                    data.map((object,index)=>
                                    <Col md="3" className="m-2" >
                                        <Card className="bg-gradient-primary text-white">
                                            <CardImg alt="..."
                                                     style={{height: '300px'}}
                                                     src={object.foto?object.foto:require("assets/img/brand/perro.jpg").default}
                                                     top />
                                                <CardBody>
                                                    <CardTitle className="text-center"> 
                                                        <Badge className="badge-secondary" style={{width:'100%'}}>
                                                            <h2>{object.nombre}<br/>
                                                            {object.codigo}</h2>
                                                        </Badge>
                                                    </CardTitle>
                                                    <CardText>
                                                        <Row>
                                                            <Col className="col-sm">
                                                                <p className="" style={{marginBottom: '1px'}}>{object.edad} Meses</p>
                                                                <strong className="text-defaut font-weight-bold">Edad </strong>
                                                            </Col>
                                                            <Col className="col-sm">
                                                                <p className="" style={{marginBottom: '1px'}}>{object.sexo_nombre}</p>
                                                                <strong className="text-defaut font-weight-bold">Sexo </strong>
                                                            </Col>
                                                            <Col className="col-sm">
                                                                <p className="" style={{marginBottom: '1px'}}>{object.raza?object.raza:" - "}</p>
                                                                <strong className="text-defaut font-weight-bold">Raza </strong>
                                                            </Col>
                                                        </Row>
                                                    </CardText>

                                                    <CardText>
                                                    <Card body className="bg-gradient-default" style={{padding:"5px"}}>
                                                        <CardTitle tag="h5" style={{marginBottom:"1px"}} className="text-white" >Hobbies:</CardTitle>
                                                        <CardText className="text-sm">{object.hobbies?object.hobbies:" - "}</CardText>
                                                    </Card>
                                                    </CardText>

                                                    <CardText>
                                                    <Card body className="bg-gradient-default" style={{padding:"5px"}} >
                                                        <CardTitle tag="h5" style={{marginBottom:"1px"}} className="text-white" >Alimentación:</CardTitle>
                                                        <CardText className="text-sm">{object.alimentacion?object.alimentacion:" - "}</CardText>
                                                    </Card>
                                                    </CardText>

                                                    <CardText>
                                                        <Card body className="bg-gradient-default" style={{padding:"5px"}}>
                                                            <CardTitle tag="h5" style={{marginBottom:"1px"}} className="text-white">Medicamentos:</CardTitle>
                                                            <CardText className="text-sm">{object.medicamentos?object.medicamentos:" - "}</CardText>
                                                        </Card>
                                                    </CardText>

                                                </CardBody>
                                                <CardFooter className="bg-gradient-primary text-center">

                                                    <Button
                                                        color="success"
                                                        onClick={() => selecionarMascota(object)}>
                                                                Actualizar información
                                                    </Button>

                                                </CardFooter>
                                        </Card>
                                    </Col>
                                    )
                                }


                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
}

export default ListaMascotaPropietario;