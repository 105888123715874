import React from "react";
import axios from "axios";
import AuthContext from "context/auth";
import { useContext, useState } from "react";
import NumberFormat from "react-number-format";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import fileDownload from "js-file-download";

const Consolidado = () => {
  const { token, url_main, setToggleAlert, setToggleSpinner } =
    useContext(AuthContext);
  let url = url_main + "mascota/generar-reporte-consolidado/";
  let url_excel_general = url_main + "mascota/generar-excel-consolidado/";
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  // const [dataRange, setDataRange] = useState([]);
  const [formBuscar, setFormBuscar] = useState({
    fecha_inicio: "",
    fecha_fin: "",
  });

  const peticionGet = async () => {
    await axios
      .get(
        url +
          "?fecha_inicio=" +
          formBuscar.fecha_inicio +
          "&fecha_fin=" +
          formBuscar.fecha_fin,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.list);
        // setDataRange(response.data.range_list_date);
        setToggleSpinner(false);
        setCount(response.data.total);
        setToggleAlert({
            isOpen: true,
            message: "Lista actualizada correctamente",
            type: "success",
          });
      })
      .catch((error) => {
        setToggleSpinner(false);
        setToggleAlert({
            isOpen: true,
            message: " Error," + error.message,
            type: "danger",
          });
      });
  };

  const handleChangeFormBuscar = async (e) => {
    e.persist();
    await setFormBuscar({
      ...formBuscar,
      [e.target.name]: e.target.value,
    });
  };

  const submitBuscarForm = (e) => {
    setToggleSpinner(true);
    e.preventDefault();
    peticionGet();
  };

  const handleDownload = (url, filename) => {
    setToggleSpinner(true);

    var query =
      "?fecha_inicio=" +
      formBuscar.fecha_inicio +
      "&fecha_fin=" +
      formBuscar.fecha_fin;

    axios
      .get(url + query, {
        responseType: "blob",
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((res) => {
        fileDownload(res.data, filename);
        setToggleSpinner(false);
        setToggleAlert({
            isOpen: true,
            message: "Reporte descargado correctamente",
            type: "success",
          });
      });
  };

  return (
    <>
      <div className="header bg-gradient-default pb-8 pt-0 pt-md-6">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h1 className="text-danger">
                  TOTAL:
                  <NumberFormat
                    className="ml-3"
                    value={count}
                    displayType="text"
                    thousandSeparator={true}
                    prefix=""
                  />
                </h1>
                <h3 className="mb-0">
                  Reporte consolidado por establecimientos
                </h3>
              </CardHeader>

              <CardBody className="px-lg-5 py-lg-2">
                <Form onSubmit={submitBuscarForm}>
                  <Row>
                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="fecha_inicio"
                        >
                          Fecha inicio
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="fecha_inicio"
                          name="fecha_inicio"
                          placeholder=""
                          required="required"
                          value={formBuscar["fecha_inicio"]}
                          onChange={handleChangeFormBuscar}
                          type="date"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="fecha_fin"
                        >
                          Fecha fin
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="fecha_fin"
                          name="fecha_fin"
                          placeholder=""
                          required="required"
                          value={formBuscar["fecha_fin"]}
                          onChange={handleChangeFormBuscar}
                          type="date"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-control-label text-white"
                          htmlFor="nombres"
                        >
                          Buscar
                        </label>
                        <Input
                          className="form-control-alternative btn-info"
                          placeholder=""
                          value="Buscar"
                          type="submit"
                        ></Input>
                      </FormGroup>
                    </Col>
                    {count ? (
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label text-white"
                            htmlFor="nombres"
                          >
                            Generar excel
                          </label>
                          <Input
                            className="form-control-alternative btn-success"
                            placeholder=""
                            value="Generar Excel"
                            onClick={() => {
                              handleDownload(
                                url_excel_general,
                                "reporte_consolidado.xlsx"
                              );
                            }}
                            type="button"
                          ></Input>
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </Form>
              </CardBody>

              <Table className="align-items-center table-dark" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">RED INTEGRADA DE SALUD</th>
                    <th scope="col">ESTABLECIMIENTO DE SALUD</th>
                    <th scope="col">FECHA</th>
                    <th scope="col">NUMERO DE CANES REGISTRADOS</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((object, index) => (
                    <tr key={object.id}>
                      <td>{object.ris}</td>
                      <td>{object.nombre}</td>
                      <td>
                        {formBuscar.fecha_inicio.split("-")[2] +
                          "-" +
                          formBuscar.fecha_inicio.split("-")[1] +
                          "-" +
                          formBuscar.fecha_inicio.split("-")[0]}
                        |
                        {formBuscar.fecha_fin.split("-")[2] +
                          "-" +
                          formBuscar.fecha_fin.split("-")[1] +
                          "-" +
                          formBuscar.fecha_fin.split("-")[0]}
                      </td>
                      <td>
                        <Badge
                          color=""
                          className="badge-dot text-upper text-strong"
                        >
                          <i className="bg-success" />
                        </Badge>{" "}
                        {object.total}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Consolidado;
