import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import QRCode from "react-qr-code";
import fileDownload from "js-file-download";
import { useContext, useState } from "react";
import AuthContext from "context/auth";
import axios from "axios";

const CardDog = ({ modalCarDog, setModalCardDog, mascota }) => {
  const { url_main } = useContext(AuthContext);
  let url_credencial_pdf =
    url_main + "mascota/generar-credencial-pdf/" + mascota.codigo;
  let value_qr =
    "https://vancan.dirislimasur.gob.pe/credencial/mascota/" + mascota.codigo;
  const [spinner, setSpinner] = useState(false);

  const pdfDownload = () => {
    setSpinner(true);
    axios
      .get(url_credencial_pdf, {
        responseType: "blob",
        headers: {},
      })
      .then((res) => {
        fileDownload(res.data, "credencial.pdf");
        setSpinner(false);
      });
  };

  return (
    <>
      <Modal className="modal-dialog-centered modal-lg" isOpen={spinner}>
        <div className="modal-body text-center">
          <Spinner
            className="spinners"
            type="grow"
            color="danger"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>Procesando información...</p>
        </div>
      </Modal>

      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Perfil del Propietario Responsable
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setModalCardDog(!modalCarDog)}
        >
          <span className="text-white" aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body">
        <Card className="card-profile shadow">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img
                    style={{
                      maxWidth: "222px",
                      maxHeight: "222px",
                      width: "222px",
                      height: "222px",
                    }}
                    alt="..."
                    className="rounded-circle"
                    src={
                      mascota.foto
                        ? mascota.foto
                        : require("../../assets/img/brand/perro.jpg").default
                    }
                  />
                </a>
              </div>
            </Col>
          </Row>

          <CardHeader className="text-center border-0 pt-8 pt-md-0 pb-0 pb-md-0 title-card-desktop">
            <div className="d-flex justify-content-between mt-3">
              <QRCode
                value={value_qr}
                title="sdsd"
                size={180}
                fgColor="#f5365c"
                level="L"
              />
              <h3>{mascota.codigo}</h3>
            </div>
          </CardHeader>

          <CardHeader className="title-card-mobile mt-5">
            <div className="col-12 text-center mt-8 ">
              <QRCode
                value={value_qr}
                title="sdsd"
                size={180}
                fgColor="#f5365c"
                level="L"
              />
              <br />
            </div>
            <div className="col-12 text-center ">
              <h1>{mascota.codigo}</h1>
            </div>
          </CardHeader>

          <CardBody className="pt-0 pt-md-0 text-center">
            <h1 className="text-strong">
              <Badge className="badge-default h1 text-uppercase" pill>
                {mascota.nombre}
              </Badge>
            </h1>

            <Row>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center mt-md-0">
                  <div>
                    <span className="heading">
                      {mascota.get_edad ? mascota.get_edad : "-"}
                    </span>
                    <span className="description">Meses</span>
                  </div>
                  <div>
                    <span className="heading">
                      {mascota.sexo_nombre ? mascota.sexo_nombre : "-"}
                    </span>
                    <span className="description">Sexo</span>
                  </div>
                  <div>
                    <span className="heading">
                      {mascota.raza ? mascota.raza : "-"}
                    </span>
                    <span className="description">Raza</span>
                  </div>
                </div>
              </div>
            </Row>
            <div className="text-center">
              <hr className="my-4" />

              {mascota.dosis_lista.map((object) => (
                <Card className="card-stats mb-4 mb-lg-0" key={object.id}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          <span className="h1">
                            VACUNA DOSIS {object.dosis}{" "}
                          </span>
                          <span className="h2 font-weight-bold mb-0">
                            {" "}
                            - Fecha: {object.fecha_format}
                          </span>
                        </CardTitle>
                        <blockquote className="blockquote">
                          <p className="mb-0">{object.vacuna_object.nombre}</p>
                          <footer className="blockquote-footer">
                            DIRIS LIMA SUR{" "}
                            <cite title="Source Title">
                              {" "}
                              - {object.establecimiento_object.nombre}
                            </cite>
                          </footer>
                        </blockquote>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              ))}

              <button
                onClick={() => pdfDownload()}
                className="btn btn-info mt-4"
              >
                Descargar PDF
              </button>
            </div>
          </CardBody>
        </Card>
      </div>

      <div className="modal-footer">
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => setModalCardDog(!modalCarDog)}
        >
          Cerrar
        </Button>
      </div>
    </>
  );
};

export default CardDog;
