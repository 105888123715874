import { useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Modal, Row } from "reactstrap";
import CardDog from "./CardDog";

const ListDog = ({ mascotas }) => {

    const [modalCarDog, setModalCardDog] = useState(false);
    const [mascota, setMascota] = useState({});


    const elegirMascota = (mascota) => {
        setMascota(mascota);
        setModalCardDog(true);
    }

    return (<>

        <Modal
            className="modal-dialog-centered  modal-lg"
            contentClassName="bg-gradient-default"
            isOpen={modalCarDog}
            toggle={() => setModalCardDog(!modalCarDog)}>
            {
                modalCarDog ?
                    <CardDog modalCarDog={modalCarDog}
                        setModalCardDog={setModalCardDog}
                        mascota={mascota} />
                    : null
            }
        </Modal>


        {mascotas.map(object => (

            <Card key={object.id} className="card-stats mb-4 mb-lg-2">
                <CardBody>
                    <Row>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="fas fa-dog" />
                            </div>
                        </Col>
                        <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0">
                                <Button color="warning"
                                    type="button"
                                    className="btn btn-block"
                                    onClick={() => elegirMascota(object)}
                                >
                                    {object.nombre}
                                </Button>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{object.codigo}</span>
                        </div>

                    </Row>
                </CardBody>
            </Card>

        ))}


        {/* <Button color="warning" type="button" className="btn btn-block">
            <span className="btn-inner--icon">
                <i className="fas fa-dog" />
            </span>
            <span className="btn-inner--text">{object.codigo} -  {object.nombre}</span>
        </Button> */}

    </>);
}



export default ListDog;