import { Button, Table } from "reactstrap";
import ListDog from "./ListDog";


const ProfilePerson = ({propietario,modalProfilePerson,setModalProfilePerson}) => {
    return (<>
        <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
                Perfil del Propietario Responsable
            </h6>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalProfilePerson(!modalProfilePerson)}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>
        <div className="modal-body">
            <div className="py-3 text-center">
                <i className="ni ni-single-02 ni-3x" />
                <h5 className="text-white">PROPIETARIO:</h5>
                <h4 className="heading mt-4">{propietario.nombre_completo}</h4>
                <p>DIRECCIÓN: {propietario.direccion} </p>
            </div>
            <div className="text-center">
                <h3 className="text-white">Lista de Mascotas</h3>
                
                <ListDog mascotas={propietario.mascotas} />
            </div>
        </div>
        <div className="modal-footer">

            <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalProfilePerson(!modalProfilePerson)}
            >
                Cerrar
            </Button>
        </div>
    </>);
}

export default ProfilePerson;