import React, { useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "context/auth";
import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";
import CardMascota from "./CardMascota";

const ListaMascota = ({ propietario }) => {
  const { token, url_main, setToggleAlert, setToggleSpinner } =
    useContext(AuthContext);
  let url = url_main + "api/mascotas/";
  const [data, setData] = useState([]);
  const [cardFormGuardar, setCardFormGuardar] = useState(false);
  const [colorBotton, setColorBotton] = useState("success");
  const [tipoModal, setTipoModal] = useState("insertar");

  const [formMascota, setFormMascota] = useState({
    id: "",
    url: "",
    propietario: propietario.url,
    nombre: "",
    sexo: "1",
    color: "",
    raza: "",
    edad: "",
    vacuna: "",
    fecha_vacuna: "",
    dosis: "",
    establecimiento: "",
    ris_id: "",
    valor: "",
  });

  const peticionGet = async () => {
    await axios
      .get(url + "?propietario=" + propietario.id, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.results);
      })
      .catch((error) => {
        setToggleAlert({
          isOpen: true,
          message: " Error," + error.message,
          type: "danger",
        });
      });
  };

  const showGuardar = () => {
    setCardFormGuardar(!cardFormGuardar);
    setTipoModal("insertar");

    if (cardFormGuardar) {
      setColorBotton("success");
    } else {
      setColorBotton("warning");
    }

    setFormMascota({
      id: "",
      url: "",
      propietario: propietario.url,
      nombre: "",
      sexo: "1",
      color: "",
      raza: "",
      edad: "",
      vacuna: "",
      fecha_vacuna: "",
      dosis: "",
      establecimiento: "",
      ris_id: "",
      valor: "",
    });
  };

  const seleccionarObjetoActualizar = (objecto) => {
    setFormMascota({
      id: "",
      url: "",
      propietario: propietario.url,
      nombre: "",
      sexo: "1",
      color: "",
      raza: "",
      edad: "",
      vacuna: "",
      fecha_vacuna: "",
      dosis: "",
      establecimiento: "",
      ris_id: "",
      valor: "",
    });

    if (objecto.id === formMascota.id && cardFormGuardar) {
      setTimeout(
        function () {
          setCardFormGuardar(false);
        }.bind(this),
        200
      );
    } else {
      setToggleSpinner(true);

      setToggleAlert({
        isOpen: true,
        message: "Mascota seleccionada para editar: " + objecto.nombre,
        type: "info",
      });

      setTimeout(
        function () {
          //Start the timer
          setTipoModal("actualizar");
          setFormMascota(objecto);
          setColorBotton("warning");
          setCardFormGuardar(true);
          setToggleSpinner(false);
        }.bind(this),
        600
      );
    }
  };

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <Button
            className="btn-icon "
            color={colorBotton}
            type="button"
            id="agregar_mascota"
            onClick={() => showGuardar()}
            style={{ margin: "4px !important" }}
          >
            {cardFormGuardar ? (
              <span className="btn-inner--icon">
                <i className="ni ni-fat-delete ni-2x" />
              </span>
            ) : (
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add " />
              </span>
            )}
          </Button>
          <UncontrolledTooltip
            delay={0}
            placement="top"
            target="agregar_mascota"
          >
            Nueva mascota
          </UncontrolledTooltip>
        </CardHeader>

        {cardFormGuardar == false || tipoModal === "actualizar" ? (
          <CardBody>
            <p>Lista de mascotas</p>
            {data.map((object) => (
              <Button
                key={object.id}
                block
                color="info"
                size="lg"
                type="button"
                className={
                  formMascota.id === object.id ? "btn-icon active" : "btn-icon"
                }
                style={{ margin: "4px !important" }}
                onClick={() => seleccionarObjetoActualizar(object)}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-dog" />
                </span>
                <span className="btn-inner--text">
                  {object.codigo} | {object.nombre} - {object.sexo_nombre}
                </span>
              </Button>
            ))}
          </CardBody>
        ) : null}

        {cardFormGuardar ? (
          <CardBody>
            <CardMascota
              formMascota={formMascota}
              setFormMascota={setFormMascota}
              peticionGetMascota={peticionGet}
              url={url}
              cardFormGuardar={cardFormGuardar}
              setCardFormGuardar={setCardFormGuardar}
              tipoModal={tipoModal}
              setTipoModal={setTipoModal}
              setColorBotton={setColorBotton}
            />
          </CardBody>
        ) : null}
      </Card>
    </>
  );
};

export default ListaMascota;
