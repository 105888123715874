
import Register from "views/examples/Register.js";
import Login from "views/Login.jsx";


import Main from "views/Main.jsx";
import ListPerson from "views/persona/ListPersona";
import ListaMascotaDigitador from "views/mascota_digitador/ListaMascotaDigitador";
import ListaMascotaPropietario from "views/propietario_mascota/ListaMascotaPropietario";
import Mascota from "views/credencial/Mascota";
import PorEstablecimiento from "views/reportes/PorEstablecimiento";
import Consolidado from "views/reportes/Consolidado";
import ListaMascotaRis from "views/reportes/ListaMascotaRis";
import ListaDosisDigitadores from "views/reportes/ListaDosisDigitadores";

var routes = [

  {
    path: "/propietarios",
    name: "Propietarios",
    icon: "ni ni-badge text-warning",
    component: ListPerson,
    layout: "/admin",
  },

  {
    path: "/propietario-mascotas",
    name: "Personalizar Mascota",
    icon: "ni ni-tv-2 text-primary",
    component: ListaMascotaPropietario,
    layout: "/admin",
  },
  {
    path: "/mascotas",
    name: "Mascotas",
    icon: "ni ni-planet text-blue",
    component: ListaMascotaDigitador,
    layout: "/admin",
  },
  {
    path: "/reporte-establecimiento",
    name: "Reporte por fechas",
    icon: "ni ni-pin-3 text-orange",
    component: PorEstablecimiento,
    layout: "/admin",
  },

  {
    path: "/reporte-consolidado",
    name: "Reporte consolidado",
    icon: "ni ni-bullet-list-67 text-green",
    component: Consolidado,
    layout: "/admin",
  },


  {
    path: "/mascota-ris",
    name: "Mascota ris",
    icon: "ni ni-single-02 text-yellow",
    component: ListaMascotaRis,
    layout: "/admin",
  },

  {
    path: "/dosis-digitadores",
    name: "N° Vacuna Digitadores",
    icon: "ni ni-single-02 text-blue",
    component: ListaDosisDigitadores,
    layout: "/admin",
  },

  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/index",
    name: "Main",
    icon: "ni ni-circle-08 text-pink",
    component: Main,
    layout: "/main",
  },

  {
    path: "/mascota",
    name: "Mascota",
    icon: "ni ni-pin-3 text-orange",
    component: Mascota,
    layout: "/credencial",
  },

];
export default routes;
