import axios from "axios";
import AuthContext from "context/auth";
import React, { useContext, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, Row, Spinner, UncontrolledAlert } from "reactstrap";
import ProfilePerson from "./card_dog/ProfilePerson";

const Main = () => {

    const {  url_main } = useContext(AuthContext);

    let url = url_main + "api/consulta-propietario/";
    const [form, setForm] = useState({
        dni: ''
    });
    const [errors, setErrors] = useState({});
    const [formValid, setFormValid] = useState(false);
    const [modalProfilePerson, setModalProfilePerson] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [propietario, setPropietario] = useState({});
    const [formValidNoExiste, setFormValidNoExiste] = useState(false);


    const peticionGet = async (dni) => {
        await axios.get(url + dni).then(response => {

            if (parseInt(response.data.id) > 0) {
                setPropietario(response.data);
                setSpinner(false);
                setModalProfilePerson(true);
            } else {
                console.log("FFFFF  8888")
                setSpinner(false);
                setFormValidNoExiste(true);
                setTimeout(function () {
                    setFormValidNoExiste(false);
                }, 3000);
            }
            // 
        }).catch(error => {
            setSpinner(false);
            setFormValidNoExiste(true);
            console.log(error.message);
            setTimeout(function () {
                setFormValidNoExiste(false);
            }, 3000);

        })
    }


    const handleValidation = () => {

        var valid = false;

        setFormValid(false);

        if (form["dni"] === '' || form["dni"].length < 8) {
            console.log("ERRRORRR");
            valid = true;
            setFormValid(true);
            setErrors({
                ...errors,
                'dni': "is-invalid"
            });

        } else {
            setErrors({
                ...errors,
                'dni': "is-valid"
            });
        }
        return valid;
    }

    const handleChange = async e => {

        e.persist();
        await setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    const submitForm = e => {
        e.preventDefault();
        setPropietario({})

        if (handleValidation()) {
            setModalProfilePerson(false);
        } else {
            setSpinner(true);
            peticionGet(form.dni);
        }
    }

    return (
        <>
            <Modal
                className="modal-dialog-centered modal-lg"
                isOpen={spinner}>
                <div className="modal-body text-center">
                    <Spinner className="spinners" type="grow" color="danger" style={{ width: '5rem', height: '5rem' }} />
                    <p>
                        Procesando información...
                    </p>
                </div>
            </Modal>

            <Modal
                className="modal-dialog-centered modal-danger modal-lg"
                contentClassName="bg-gradient-default"
                isOpen={modalProfilePerson}
                toggle={() => setModalProfilePerson(!modalProfilePerson)}>

                {
                    modalProfilePerson ?
                        <ProfilePerson
                            propietario={propietario}
                            modalProfilePerson={modalProfilePerson}
                            setModalProfilePerson={setModalProfilePerson} />
                        : null
                }

            </Modal>


            <Container className="mt-lg-7 pb-4 text-center">

                <UncontrolledAlert className="alert-warning position-fixed " fade={true} isOpen={formValidNoExiste} onClick={() => { setFormValid(false) }}>
                    <span className="alert-inner--icon">
                        <i className="ni ni-bell-55" />
                    </span>{" "}
                    <span className="alert-inner--text">
                        <strong>Ups!</strong> El propietario aún no se encuentra registrado.
                        <br />
                        Vuelva a intentarlo más tarde.
                    </span>
                </UncontrolledAlert>


                <UncontrolledAlert className="alert-danger position-fixed " fade={true} isOpen={formValid} onClick={() => { setFormValid(false) }}>
                    <span className="alert-inner--icon">
                        <i className="ni ni-bell-55" />
                    </span>{" "}
                    <span className="alert-inner--text">
                        <strong>Cuidado!</strong> Ingrese un DNI válido.
                    </span>
                </UncontrolledAlert>


                <Row className="justify-content-center mt-6">

                    <div style={{ width: "35rem" }}>
                        <Card className="card-stats mb-4 mb-lg-0 p-5">
                            <CardBody>
                                <Form
                                    onSubmit={submitForm}
                                    method="post"
                                    encType="multipart/form-data"
                                >
                                    <Row>
                                        <div className="col mb-4">
                                            <CardTitle className=" text-muted mb-0">
                                                Para descargar el certificado digital de su mascota
                                                debe ingresar su DNI.
                                            </CardTitle>
                                        </div>

                                        <Col md="12">
                                            <FormGroup>
                                                <InputGroup className="mb-4">
                                                    <Input placeholder="Buscar por DNI" type="text" name="dni"
                                                        className={errors["dni"]}
                                                        value={form["dni"]}
                                                        onChange={handleChange}
                                                    />
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-zoom-split-in" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col md="12 text-center">
                                            <Button className="btn-icon btn-3" color="primary" type="submit">
                                                <span className="btn-inner--icon">
                                                    <i className="ni ni-badge" />
                                                </span>
                                                <span className="btn-inner--text">Buscar</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>

                            </CardBody>
                        </Card>
                    </div>

                </Row>
            </Container>

        </>
    );
}

export default Main;