import axios from "axios";
import AuthContext from "context/auth";
import fileDownload from "js-file-download";
import React, { useContext, useState } from "react";
import NumberFormat from "react-number-format";
import ReactPaginate from "react-paginate";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import ris from "ris";

const ListaMascotaRis = () => {
  const { token, url_main, setToggleAlert, setToggleSpinner } =
    useContext(AuthContext);
  let url = url_main + "api/dosis/";
  let url_excel_general = url_main + "mascota/generar-excel-mascota-ris";
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [formBuscar, setFormBuscar] = useState({
    fecha_inicio: "",
    fecha_fin: "",
    ris: "",
  });
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  let pagination = 30;

  const peticionGet = async (page) => {
    await axios
      .get(
        url +
          "?page=" +
          page +
          "&fecha_inicio=" +
          formBuscar.fecha_inicio +
          "&fecha_fin=" +
          formBuscar.fecha_fin +
          "&ris=" +
          formBuscar.ris,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.results);
        setToggleSpinner(false);
        setCount(response.data.count);
        setPageCount(Math.ceil(response.data.count / pagination));
        setToggleAlert({
          isOpen: true,
          message: "Lista actualizada correctamente",
          type: "success",
        });
      })
      .catch((error) => {
        setToggleSpinner(false);
        setToggleAlert({
          isOpen: true,
          message: " Error," + error.message,
          type: "danger",
        });
      });
  };

  const handleChangeFormBuscar = async (e) => {
    e.persist();
    await setFormBuscar({
      ...formBuscar,
      [e.target.name]: e.target.value,
    });
  };

  const submitBuscarForm = (e) => {
    e.preventDefault();
    if (formBuscar.fecha_inicio > formBuscar.fecha_fin) {
      setFormBuscar({
        ...formBuscar,
        fecha_inicio: "",
        fecha_fin: "",
      });
    } else {
      setToggleSpinner(true);
      setCurrentPage(1);
      peticionGet(1);
    }
  };

  const handleDownload = (url, filename) => {
    setToggleSpinner(true);

    axios
      .get(
        url +
          "?fecha_inicio=" +
          formBuscar.fecha_inicio +
          "&fecha_fin=" +
          formBuscar.fecha_fin +
          "&ris=" +
          formBuscar.ris,
        {
          responseType: "blob",
          headers: {
            Authorization: `token ${token}`,
          },
        }
      )
      .then((res) => {
        fileDownload(res.data, filename);
        setToggleSpinner(false);
        setToggleAlert({
          isOpen: true,
          message: "Reporte descargado correctamente",
          type: "success",
        });
      });
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    setCurrentPage(selected + 1);
    peticionGet(selected + 1);
  };

  return (
    <>
      <div className="header bg-gradient-default pb-8 pt-0 pt-md-6">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h1 className="text-danger">
                  TOTAL:
                  <NumberFormat
                    className="ml-3"
                    value={count}
                    displayType="text"
                    thousandSeparator={true}
                    prefix=""
                  />
                </h1>
                <h3 className="mb-0">Lista de mascotas por ris</h3>
                <Form onSubmit={submitBuscarForm}>
                  <Row>
                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="fecha_inicio"
                        >
                          Fecha inicio
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="fecha_inicio"
                          name="fecha_inicio"
                          placeholder=""
                          required="required"
                          value={formBuscar["fecha_inicio"]}
                          onChange={handleChangeFormBuscar}
                          type="date"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="fecha_fin"
                        >
                          Fecha fin
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="fecha_fin"
                          name="fecha_fin"
                          placeholder=""
                          required="required"
                          value={formBuscar["fecha_fin"]}
                          onChange={handleChangeFormBuscar}
                          type="date"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="ris">
                          RIS
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="ris"
                          name="ris"
                          placeholder=""
                          value={formBuscar["ris"]}
                          onChange={handleChangeFormBuscar}
                          required="required"
                          type="select"
                        >
                          <option value="">-- --</option>

                          {ris.map((object) => (
                            <option key={object.id} value={object.id}>
                              {object.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-control-label text-white"
                          htmlFor="nombres"
                        >
                          Buscar
                        </label>
                        <Input
                          className="form-control-alternative btn-info"
                          placeholder=""
                          value="Buscar"
                          type="submit"
                        ></Input>
                      </FormGroup>
                    </Col>
                    {count ? (
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label text-white"
                            htmlFor="nombres"
                          >
                            Generar excel
                          </label>
                          <Input
                            className="form-control-alternative btn-success"
                            placeholder=""
                            value="Generar Excel"
                            onClick={() => {
                              handleDownload(
                                url_excel_general,
                                "reporte_consolidado.xlsx"
                              );
                            }}
                            type="button"
                          ></Input>
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </Form>
              </CardHeader>
              {data.length > 0 ? (
                <>
                  <CardBody className="px-lg-5 py-lg-2">
                    <Table className="align-items-center table-dark" responsive>
                      <tr>
                        <th>#</th>
                        <th>
                          RED INTEGRADA <br />
                          DE SALUD
                        </th>
                        <th>
                          ESTABLECIMIENTO <br /> DE SALUD
                        </th>
                        <th>
                          FECHA DE <br /> VACUNACION
                        </th>
                        <th>N° VACUNA</th>
                        <th> MASCOTA</th>
                        <th>PROPIETARIO</th>
                        <th>
                          DATOS <br /> REGISTRO
                        </th>
                      </tr>
                      <tbody>
                        {data.map((object, index) => (
                          <tr key={object.id}>
                            <th scope="row">
                              {currentPage > 1
                                ? 1 + index + (currentPage - 1) * pagination
                                : index + currentPage}
                            </th>
                            <td> {object.ris_nombre} </td>
                            <td> {object.establecimiento_object.nombre} </td>
                            <td> {object.fecha_format}</td>
                            <td> {object.dosis}</td>
                            <td>
                              <span className="font-italic">
                                {object.mascota_object.nombre}
                              </span>{" "}
                              <br />
                              {object.mascota_object.codigo}
                            </td>
                            {/* <td> {object.propietario_object.nombre_completo }</td> */}
                            {/* <td  > {object.propietario_object.direccion}</td> */}
                            {/* <td> {object.propietario_object.telefono}</td> */}
                            <td>
                              <span className="font-italic">
                                {
                                  object.mascota_object.propietario_object
                                    .nombre_completo
                                }
                              </span>{" "}
                              <br />
                              {
                                object.mascota_object.propietario_object
                                  .numero_documento
                              }
                            </td>
                            {/* <td> {object.nombre}</td>
                                            <td> {object.edad} Meses</td>
                                            <td> {object.sexo_nombre}</td> */}
                            <td>
                              <strong className="text-info">
                                {object.usuario_nombre}
                              </strong>{" "}
                              <br />
                              {object.creacion_fecha} - {object.creacion_hora}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <Table className="align-items-center table-dark" responsive>
                      <tr>
                        <th>#</th>
                        <th>
                          RED INTEGRADA <br />
                          DE SALUD
                        </th>
                        <th>
                          ESTABLECIMIENTO <br /> DE SALUD
                        </th>
                        <th>
                          FECHA DE <br /> VACUNACION
                        </th>
                        <th>N° VACUNA</th>
                        <th> MASCOTA</th>
                        {/* <th >NOMBRE Y APELLIDOS <br/> DEL PROPIETARIO</th> */}
                        {/* <th style={{width:"10%"}}>DIRECCION DEL  <br/>PROPIETARIO</th> */}
                        {/* <th >TELEFONO DEL <br/> PROPIETARIO</th> */}
                        <th>PROPIETARIO</th>
                        {/* <th >NOMBRE <br/> DEL CAN</th>
                                        <th >EDAD</th>
                                        <th >SEXO</th> */}
                        <th>
                          DATOS <br /> REGISTRO
                        </th>
                      </tr>
                      <tbody>
                        {data.map((object, index) => (
                          <tr key={object.id}>
                            <th scope="row">
                              {currentPage > 1
                                ? 1 + index + (currentPage - 1) * pagination
                                : index + currentPage}
                            </th>
                            <td> {object.ris_nombre} </td>
                            <td> {object.establecimiento_object.nombre} </td>
                            <td> {object.fecha_format}</td>
                            <td> {object.dosis}</td>
                            <td>
                              <span className="font-italic">
                                {object.mascota_object.nombre}
                              </span>{" "}
                              <br />
                              {object.mascota_object.codigo}
                            </td>
                            {/* <td> {object.propietario_object.nombre_completo }</td> */}
                            {/* <td  > {object.propietario_object.direccion}</td> */}
                            {/* <td> {object.propietario_object.telefono}</td> */}
                            <td>
                              <span className="font-italic">
                                {
                                  object.mascota_object.propietario_object
                                    .nombre_completo
                                }
                              </span>{" "}
                              <br />
                              {
                                object.mascota_object.propietario_object
                                  .numero_documento
                              }
                            </td>
                            {/* <td> {object.nombre}</td>
                                            <td> {object.edad} Meses</td>
                                            <td> {object.sexo_nombre}</td> */}
                            <td>
                              <strong className="text-info">
                                {object.usuario_nombre}
                              </strong>{" "}
                              <br />
                              {object.creacion_fecha} - {object.creacion_hora}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>

                  <CardFooter>
                    <nav aria-label="Page navigation example">
                      <nav
                        className="pagination justify-content-center"
                        aria-label="pagination"
                      >
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "justify-content-center pagination"
                          }
                          activeClassName={"active"}
                          pageLinkClassName={"page-link"}
                          pageClassName={"page-item"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                        />
                      </nav>
                    </nav>
                  </CardFooter>
                </>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListaMascotaRis;
