import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Form, FormGroup, Input, Modal, Row, Spinner, Table } from "reactstrap";
import CardPersona from "./CardPersona";
import ReactPaginate from 'react-paginate';
import CardEliminarPersona from './CardEliminarPersona';
import { useContext } from 'react';
import AuthContext from 'context/auth';
import { useHistory } from 'react-router';
// import ReactPDF from '@react-pdf/renderer';
// import PdfListaMascotas from './PdfListaMascotas';

// import { PDFDownloadLink } from '@react-pdf/renderer';
// import fileDownload from 'js-file-download'

const ListPerson = () => {

    const data_user = useMemo(() => JSON.parse(localStorage.getItem("@diris_ls-data")), []);

    const { isAuthenticated, token, url_main,setToggleAlert, setToggleSpinner } = useContext(AuthContext);

    let url = url_main + "api/propietarios/";
    // let url_entidad = url_main + "api/entidades/";
    const history = useHistory();
    const [data, setData] = useState([]);
    const [modalFormGuardar, setModalFormGuardar] = useState(false);
    const [modalFormEliminar, setModalFormEliminar] = useState(false);
    const [query, setQuery] = useState('')
    const [pageCount, setPageCount] = useState(2);
    const [currentPage, setCurrentPage] = useState(1);
    const [tipoModalPersona, setTipoModalPersona] = useState("insertar");
    const [formPersona, setFormPersona] = useState({
        id: '',
        url: '',
        tipo_documento: '1',
        numero_documento: '',
        nombres: '',
        apellido_paterno: '',
        apellido_materno: '',
        direccion: '',
        telefono: '',
    });

    // const [dataEstablecimiento, setDataEstablecimiento] = useState([]);


    // const [demo, setDemo] = useState(true);


    // const peticionGetEstablecimiento = async () => {
    //     await axios.get(url_entidad + "?ris=" + data_user.ris, {
    //         headers: {
    //             'Authorization': `token ${token}`
    //         }
    //     }).then(response => {
    //         setDataEstablecimiento(response.data.results);
    //     }).catch(error => {
    //         console.log(error.message);
    //     });

    // }

    let pagination = 30;

    const peticionGet = (page) => {
        let query_ = (query ? "&query=" + query : '')
        let page_ = (page === 0 ? 1 : page)
        if (query !== '') {
            page_ = 1;
        }
        axios.get(url + "?page=" + page_ + "&usuario=" + data_user.username + query_, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            setData(response.data.results);
            setPageCount(Math.ceil(response.data.count / pagination));
            // guardarPageNumber(page);
            // setCurrentPage(page);
            setToggleSpinner(false);
        }).catch(error => {
            setToggleAlert({
                isOpen: true,
                message: " Error,"+error.message,
                type: "danger",
              });
        });

    }

    const submitBuscarForm = e => {
        e.preventDefault();
        peticionGet(currentPage);

    }


    const handleChange = async e => {
        e.persist();
        setQuery(e.target.value.toUpperCase());
    }


    const seleccionarObjetoActualizar = (objecto) => {
        setModalFormGuardar(true);
        setTipoModalPersona('actualizar')
        setFormPersona({ ...objecto, tipo_documento: objecto.tipo_documento.toString() });
    }
    const seleccionarObjetoInsertar = () => {
        setModalFormGuardar(true);
        setTipoModalPersona('insertar');
        setFormPersona({
            id: '',
            url: '',
            tipo_documento: '1',
            numero_documento: '',
            nombres: '',
            apellido_paterno: '',
            apellido_materno: '',
            direccion: '',
            telefono: '',
        });
    }

    const seleccionarObjetoEliminar = (objecto) => {
        setFormPersona(objecto);
        setModalFormEliminar(true);
    }


    const changePage = () => {
        peticionGet(currentPage);
    };

    const handlePageClick = (data) => {
        let selected = data.selected;
        setCurrentPage(selected + 1)
    };



    useEffect(() => {
        if (!isAuthenticated) {
            history.push("/main/index");
        }
        peticionGet(currentPage);
        // peticionGetEstablecimiento();
    }, []);


    useEffect(changePage, [currentPage]);




    return (<>



        <Modal
            className="modal-dialog-centered  "
            size="xl"
            isOpen={modalFormGuardar}
            toggle={() => setModalFormGuardar(!modalFormGuardar)}>
            {
                modalFormGuardar ? <CardPersona modalFormGuardar={modalFormGuardar}
                    setModalFormGuardar={setModalFormGuardar}
                    peticionGetPerson={peticionGet}
                    tipoModalPersona={tipoModalPersona}
                    formPersona={formPersona}
                    setFormPersona={setFormPersona}
                    currentPage={currentPage}
                    setTipoModalPersona={setTipoModalPersona}
                />
                    : null
            }
        </Modal>


        <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={modalFormEliminar}
            toggle={() => setModalFormEliminar(!modalFormEliminar)}>
            {
                modalFormEliminar ? <CardEliminarPersona modalFormEliminar={modalFormEliminar}
                    setModalFormEliminar={setModalFormEliminar}
                    peticionGetPerson={peticionGet}
                    formPersona={formPersona}
                    setFormPersona={setFormPersona}
                    currentPage={currentPage}
                /> : null
            }
        </Modal>


        <div className="header bg-gradient-default pb-8 pt-0 pt-md-6">
            <Container fluid>
                <div className="header-body">
                    {/* Card stats */}
                    <Row >
                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-1 mb-xl-0">
                                <CardBody >
                                    <Row >
                                        <div className="col">
                                            <CardTitle
                                                tag="h5"
                                                className="text-uppercase text-muted mb-0">
                                                <Button
                                                    className="btn-block"
                                                    onClick={() => { seleccionarObjetoInsertar() }}
                                                    color="success">Agregar nuevo propietario</Button>
                                            </CardTitle>

                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

        <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
                <div className="col">
                    <Card className="shadow">

                        <CardHeader className="border-0">
                            <h3 className="mb-0">Lista de propietarios <small>Administrados por: {data_user?.nombre_completo}</small></h3>
                        </CardHeader>

                        <CardBody className="px-lg-5 py-lg-2">

                            <Row>
                                <Col lg="3" xl="3" md="12" >
                                    <Form onSubmit={submitBuscarForm} >
                                        <Row>
                                            <Col md="7">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="query">
                                                        Buscar
                                                    </label>

                                                    <Input
                                                        className="form-control-alternative"
                                                        placeholder="Buscar propietario"
                                                        name='query'
                                                        onChange={handleChange}
                                                        type="text"
                                                        value={query ? query : ''}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">

                                                <FormGroup>
                                                    <label
                                                        className="form-control-label text-white"
                                                        htmlFor="nombres">
                                                        Buscar
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative btn-info"
                                                        placeholder=""
                                                        value="Buscar"
                                                        type="submit" >
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">{"  "}</Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Tipo de documento</th>
                                        <th scope="col">Documento</th>
                                        <th scope="col">Nombres y Apellidos</th>
                                        <th scope="col">Celular</th>
                                        <th scope="col" >Dirección</th>
                                        <th scope="col">OPC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((object, index) =>
                                        <tr key={object.id}>
                                            <th scope="row">
                                                {currentPage > 1 ?
                                                    1 + index + (currentPage - 1) * pagination
                                                    : index + currentPage}
                                            </th>
                                            <td>{object.tipo_documento_nombre}</td>
                                            <td>{object.numero_documento}</td>
                                            <td>{object.nombre_completo}</td>
                                            <td>{object.telefono}</td>
                                            <td>{object.direccion}</td>
                                            <td>
                                                <Button color="info btn-sm" onClick={() => (seleccionarObjetoActualizar(object))}>Ver</Button>{"  "}
                                                <Button color="danger btn-sm" onClick={() => (seleccionarObjetoEliminar(object))}>Eliminar</Button>{"  "}
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>


                        </CardBody>
                        <CardFooter>

                            <nav aria-label="Page navigation example">
                                <nav className="pagination justify-content-center" aria-label="pagination">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={'justify-content-center pagination'}
                                        activeClassName={'active'}
                                        pageLinkClassName={'page-link'}
                                        pageClassName={'page-item'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                    />
                                </nav>
                            </nav>
                        </CardFooter>


                    </Card>
                </div>
            </Row>
        </Container>
    </>);
}

export default ListPerson;