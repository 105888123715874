import React, { useState } from "react";
import axios from "axios";
import AuthContext from "context/auth";
import { useContext } from "react";
import { Button } from "reactstrap";

const CardEliminarPersona = ({
  setModalFormEliminar,
  peticionGetPerson,
  formPersona,
  currentPage,
}) => {
  const { token } = useContext(AuthContext);

  const [disabledBoton, guardarDisabledBoton] = useState(false);

  const peticionDelete = async () => {
    guardarDisabledBoton(true);
    await axios
      .delete(formPersona.url, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        peticionGetPerson(currentPage);
        setModalFormEliminar(false);
        guardarDisabledBoton(false);
      })
      .catch((error) => {
        console.log(error.message);
        guardarDisabledBoton(false);
      });
  };
  return (
    <>
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Esta operación requiere su atención
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setModalFormEliminar(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">Atención</h4>
          <p>Esta seguro que desea eliminar al propietario</p>
          <h3 className="text-white">{formPersona.nombre_completo}</h3>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          disabled={disabledBoton}
          className="btn-white"
          color="default"
          type="button"
          onClick={() => peticionDelete()}
        >
          Eliminar definitivamente
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => setModalFormEliminar(false)}
        >
          Cancelar
        </Button>
      </div>
    </>
  );
};

export default CardEliminarPersona;
