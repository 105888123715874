import { useCallback, useState } from "react";
import AuthContext from "./index";

const AuthProvider = ({ children }) => {

    let url_main = 'https://vacunacan.dirislimasur.gob.pe/';
    //let url_main = 'http://127.0.0.1:8000/';

    const [toggleAlert, setToggleAlert] = useState({
        isOpen: false,
        message:" Correcto",
        type:"success"
    });

    const [toggleSpinner, setToggleSpinner] = useState(false);

    const handleLogin = useCallback(async (token, data) => {
        var rol = "PROPIETARIO";

        if (token) {
            if (data.groups_object.length > 0) {
                rol = data.groups_object[0].name;
            }
            localStorage.setItem("@diris_ls-isAuth", "true");
            localStorage.setItem("@diris_ls-token", token);
            localStorage.setItem("@diris_ls-data", JSON.stringify(
                {
                    ris: data.ris,
                    username: data.username,
                    rol: rol,
                    nombre_completo: data.nombre_completo
                }
            ));
        } else {
            localStorage.setItem("@diris_ls-isAuth", "");
            localStorage.setItem("@diris_ls-token", "");
            localStorage.setItem("@diris_ls-data", "");
        }
    }, []);

    return (
        <AuthContext.Provider value={{
            isAuthenticated: localStorage.getItem("@diris_ls-isAuth")?.length > 0,
            token: localStorage.getItem("@diris_ls-token"),
            onLogin: handleLogin,
            url_main: url_main,
            toggleAlert:toggleAlert,
            setToggleAlert:setToggleAlert,
            toggleSpinner: toggleSpinner,
            setToggleSpinner: setToggleSpinner
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;