import axios from "axios";
import AuthContext from "context/auth";
import React, { useContext } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { Badge, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row, Table } from "reactstrap";

const PorEstablecimiento = () => {


    const { token, url_main, setToggleAlert, setToggleSpinner } = useContext(AuthContext);
    let url = url_main + "mascota/generar-excel-establecimiento/";
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [dataRange, setDataRange] = useState([]);
    const [formBuscar, setFormBuscar] = useState({
        fecha_inicio: '',
        fecha_fin: '',
    });

    const peticionGet = async () => {
        await axios.get(url + "?fecha_inicio=" + formBuscar.fecha_inicio +
            "&fecha_fin=" + formBuscar.fecha_fin, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            setData(response.data.list);
            setDataRange(response.data.range_list_date);
            setToggleSpinner(false);
            setCount(response.data.total);
            setToggleAlert({
                isOpen: true,
                message: "Lista actualizada correctamente",
                type: "success",
              });
            
        }).catch(error => {
            console.log(error.message);
            setToggleSpinner(false);
            setToggleAlert({
                isOpen: true,
                message: " Error," + error.message,
                type: "danger",
              });
        });

    }

    const handleChangeFormBuscar = async e => {
        e.persist();
        await setFormBuscar({
            ...formBuscar,
            [e.target.name]: e.target.value,
        }
        );
    }

    const submitBuscarForm = e => {
        setToggleSpinner(true);
        e.preventDefault();
        peticionGet();

    }

    return (<>


        <div className="header bg-gradient-default pb-8 pt-0 pt-md-6">
            <Container fluid>
                <div className="header-body">
                </div>
            </Container>
        </div>

        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow">

                        <CardHeader className="border-0">
                            <h1 className="text-danger">TOTAL:  <NumberFormat
                                value={count}
                                displayType="text"
                                thousandSeparator={true}
                                prefix=""
                            /> </h1>
                            <h3 className="mb-0">Cantidad de canes vacunados por establecimientos.</h3>

                        </CardHeader>

                        <CardBody className="px-lg-5 py-lg-2">
                            <Form onSubmit={submitBuscarForm} >
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="fecha_inicio">
                                                Fecha inicio
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="fecha_inicio"
                                                name="fecha_inicio"
                                                placeholder=""
                                                required="required"
                                                value={formBuscar["fecha_inicio"]}
                                                onChange={handleChangeFormBuscar}
                                                type="date" />
                                        </FormGroup>
                                    </Col>

                                    <Col md="2">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="fecha_fin">
                                                Fecha fin
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="fecha_fin"
                                                name="fecha_fin"
                                                placeholder=""
                                                required="required"
                                                value={formBuscar["fecha_fin"]}
                                                onChange={handleChangeFormBuscar}
                                                type="date" />
                                        </FormGroup>
                                    </Col>

                                    <Col md="2">

                                        <FormGroup>
                                            <label
                                                className="form-control-label text-white"
                                                htmlFor="nombres">
                                                Buscar
                                            </label>
                                            <Input
                                                className="form-control-alternative btn-info"
                                                placeholder=""
                                                value="Buscar"
                                                type="submit" >
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </Form>
                        </CardBody>

                        {data.map((object, index) =>
                            <>

                                <Card key={object.id} className="bg-secondary shadow border-0">
                                    <CardHeader>
                                        <h1>{object.nombre}
                                            <span className="text-info ml-4">
                                                TOTAL:
                                                <NumberFormat
                                                    value={object.total}
                                                    displayType="text"
                                                    thousandSeparator={true}
                                                    prefix=" "
                                                />
                                            </span>
                                        </h1>
                                    </CardHeader>
                                    <CardBody>

                                        <Table className="align-items-center table-dark"
                                            responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Establecimiento</th>

                                                    {
                                                        dataRange.map((object_range, index_range) =>
                                                            <th key={index_range} >
                                                                {object_range}
                                                            </th>
                                                        )
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    object.establecimientos.map((object_est, index) =>
                                                        <tr key={object_est.id} >
                                                            <td>
                                                                {object_est.nombre === "TOTAL" ?
                                                                    <Badge color="" className="badge-dot text-upper text-strong">
                                                                        <i className="bg-success" />
                                                                        {object_est.nombre}
                                                                    </Badge>
                                                                    : object_est.nombre
                                                                }
                                                            </td>
                                                            {
                                                                object_est.dias.map((object_dia, index_dia) =>
                                                                    <td key={index_dia+100} >
                                                                        {object_dia.dia === "TOTAL" || object_est.nombre === "TOTAL" ?
                                                                                <Badge color="" className="badge-dot text-upper"
                                                                                style={{fontSize:"15px"}}>
                                                                                    <i className="bg-danger" />
                                                                                    {object_dia.total}
                                                                                </Badge>
                                                                            : object_dia.total
                                                                        }

                                                                    </td>
                                                                )
                                                            }
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>

                                    </CardBody>
                                </Card>

                            </>
                        )}

                    </Card>
                </div>
            </Row>
        </Container>



    </>);
}

export default PorEstablecimiento;